import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../../misc/header';
import './clientList.css';

function ClientList() {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://ziyutecerp-9re9z.ondigitalocean.app/api/clients', {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            });
            const validClients = response.data.filter(client => client.customer_number); 
            setClients(validClients);
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    const filteredClients = keyword
    ? clients.filter(client =>
        (client.customer_name && client.customer_name.toLowerCase().includes(keyword.toLowerCase())) ||
        (client.C_Type && client.C_Type.toLowerCase().includes(keyword.toLowerCase())) ||
        (client.customer_number && client.customer_number.toString().includes(keyword)) ||
        (client.C_City && client.C_City.toLowerCase().includes(keyword.toLowerCase()))
    )
    : clients;

    return (
        <>
            <Header />
            <div className="ClientSearchBarContainer">
                <div className="ClientSearchBar">
                    <input
                        type="text"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder="Search Clients"
                    />
                </div>
                <div className="ClientTitleContainer">
                    <div className='ClientTitle'>CLIENT LIST</div>
                </div>
                <a href="/clients/new" className="createClientButton">
                    Create New Client
                </a>
            </div>

            {loading ? (
                <div className='Loading'>Loading...</div>
            ) : (
                <div>
                    <table className="clientTable">
                        <thead>
                            <tr>
                                <th>Client Number</th>
                                <th>Client Name</th>
                                <th>Client Type</th>
                                <th>City</th>
                                <th>Office Tel 1</th>
                                <th>Direct Phone</th>
                                <th>Email</th>
                                <th>Valid Client</th>
                                <th>Active C/O Clients</th>
                                <th>Potential Clients</th>
                                <th>BAD Flag</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredClients.length > 0 ? (
                                filteredClients.map(client => (
                                    <tr key={client.customer_number}>
                                        <td>
                                            <Link to={`/clients/${client.customer_number}`} className="clientLink">
                                                {client.customer_number}
                                            </Link>
                                        </td>
                                        <td>{client.customer_name}</td>
                                        <td>{client.C_Type}</td>
                                        <td>{client.C_City}</td>
                                        <td>{client.C_Tel_1}</td>
                                        <td>{client.C_Tel_D}</td>
                                        <td>{client.C_email}</td>
                                        <td>{client.isValidClient ? 'YES' : 'NO'}</td>
                                        <td>{client.isActiveCoClient ? 'YES' : 'NO'}</td>
                                        <td>{client.isPotentialClient ? 'YES' : 'NO'}</td>
                                        <td>{client.C_BAD_Flag}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="11" className="noResults">No results found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default ClientList;
