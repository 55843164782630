import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from '../../misc/header';
import './mepVendorView.css';

function VendorView() {
    const { vendorNumber } = useParams(); 
    console.log('Vendor Number:', vendorNumber);
    const [vendor, setVendor] = useState({
        number: '',
        name: '',
        owner: '',
        type: '',
        payment_type: '',
        province: '',
        city: '',
        postal_code: '',
        address: '',
        tel_1: '',
        tel_2: '',
        tel_d: '',
        email: '',
        web: '',
        notes: '',
        bad_flag: ''
    });
    const [loading, setLoading] = useState(true);

    const fetchVendor = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/vendors/${vendorNumber}`);
            setVendor(response.data);
            console.log('Fetched Vendor:', response.data);
        } catch (error) {
            console.error('Error fetching vendor:', error);
        } finally {
            setLoading(false);
        }
    }, [vendorNumber]);

    useEffect(() => {
        fetchVendor();
    }, [fetchVendor]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVendor(prevVendor => ({
            ...prevVendor,
            [name]: value
        }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://ziyutecerp-9re9z.ondigitalocean.app/api/updateVendor/${vendorNumber}`, vendor);
            window.location.href = '/vendors/MEP/' + vendor.SC_number;
        } catch (error) {
            console.error('Error updating vendor:', error);
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        const confirmDelete = window.confirm('Are you sure you want to delete this vendor?');
        if (confirmDelete) {
            try {
                await axios.delete(`https://ziyutecerp-9re9z.ondigitalocean.app/api/deleteVendor/${vendorNumber}`);
                window.location.href = '/vendors/MEP';
            } catch (error) {
                console.error('Error deleting vendor:', error);
            }
        }
    };

    return (
        <>
            <Header />
            <div className="vendorViewContainer">
                {loading ? (
                    <div className='Loading'>Loading...</div>
                ) : (
                    <div className="vendorDetail">
                        <div className="vendorDetailTop">
                            <h1 className="vendorNumber">Vendor Number: {vendor.SC_number}</h1>
                            <button onClick={handleUpdate} className="updateButton">Update</button>
                        </div>
                        <form className="vendorForm">
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Vendor Number:</label>
                                    <input type="text" name="SC_number" value={vendor.SC_number} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Vendor Name:</label>
                                    <input type="text" name="SC_name" value={vendor.SC_name} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Owner:</label>
                                    <input type="text" name="SC_Owner" value={vendor.SC_Owner} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Type:</label>
                                    <input type="text" name="SC_type" value={vendor.SC_type} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Address:</label>
                                    <input type="text" name="SC_Address" value={vendor.SC_Address} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Postal Code:</label>
                                    <input type="text" name="SC_Postal_Code" value={vendor.SC_Postal_Code} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>City:</label>
                                    <input type="text" name="SC_City" value={vendor.SC_City} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Province:</label>
                                    <input type="text" name="SC_Province" value={vendor.SC_Province} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Payment Type:</label>
                                    <input type="text" name="SC_Payment_Type" value={vendor.SC_Payment_Type} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Tel 1:</label>
                                    <input type="text" name="SC_Tel_1" value={vendor.SC_Tel_1} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Tel 2:</label>
                                    <input type="text" name="SC_Tel_2" value={vendor.SC_Tel_2} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Direct Phone:</label>
                                    <input type="text" name="SC_Tel_D" value={vendor.SC_Tel_D} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Email:</label>
                                    <input type="text" name="SC_Email" value={vendor.SC_Email} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Web:</label>
                                    <input type="text" name="SC_Web" value={vendor.SC_Web} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Notes:</label>
                                    <textarea name="SC_Notes" value={vendor.SC_Notes} onChange={handleChange}></textarea>
                                </div>
                            </div>
                            <div className="formRow formRowBottom">
                                <div className="formGroup">
                                    <label>Bad Flag:</label>
                                    <select name="SC_Bad_Flag" value={vendor.SC_Bad_Flag} onChange={handleChange}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                <div className="deleteButtonContainer">
                                    <button onClick={handleDelete} className="deleteButton">Delete</button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
}

export default VendorView;