import React from 'react';
import './headerNFP.css'; 
import image from '../ziyutec logo long.png';

class HeaderNFP extends React.Component {
    render() {
        return (
            <header className="HeaderNFP">
                <div className="logo">
                    <a href="/">
                        <img src={image} alt="Company Logo" /> {}
                    </a>
                </div>
            </header>
        )}
    }

export default HeaderNFP;