import React from 'react';
import './nfp.css';
import HeaderNFP from '../../misc/headerNFP';

class NFP extends React.Component {
  render() {
    return (
      <div className="NFP">
        <HeaderNFP />
        <h1>404 Error: Page not found</h1>
        <p>Please check the URL and try again</p>
      </div>
    );
  }
}

export default NFP;