import Header from '../../misc/header';
import './subVendorView.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './subVendorCreate.css';

function SubCreate() {
    const navigate = useNavigate();
    const [sub, setSub] = useState({
        number: '',
        name: '',
        owner: '',
        type: '',
        payment_type: '',
        province: '',
        city: '',
        postal_code: '',
        address: '',
        tel_1: '',
        tel_2: '',
        tel_d: '',
        email: '',
        web: '',
        notes: '',
        bad_flag: ''
    });
    const [loading, setLoading] = useState(false);

    const mapSubToApiFormat = (sub) => {
        return {
            SC_number: sub.number,
            SC_name: sub.name,
            SC_Owner: sub.owner,
            SC_type: sub.type,
            SC_payment_type: sub.payment_type,
            SC_Province: sub.province,
            SC_City: sub.city,
            SC_Postal_Code: sub.postal_code,
            SC_Address: sub.address,
            SC_Tel_1: sub.tel_1,
            SC_Tel_2: sub.tel_2,
            SC_Tel_D: sub.tel_d,
            SC_Email: sub.email,
            SC_Web: sub.web,
            SC_Notes: sub.notes,
            SC_Bad_flag: sub.bad_flag
        };
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSub(prevSub => ({
            ...prevSub,
            [name]: value
        }));
    };

    const handleCreate = async () => {
        console.log('Sub Number:', sub.number);
        if (!sub.number || sub.number.trim() === '') {
            alert('Sub Number cannot be empty');
            return;
        }

        setLoading(true);
        try {
            const subData = mapSubToApiFormat(sub);
            await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/addSub', subData);
            navigate(`/vendors/sub/${subData.SC_number}`);
        } catch (error) {
            console.error('Error creating sub:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <div className="subViewContainer">
                {loading ? (
                    <div className='Loading'>Loading...</div>
                ) : (
                    <div className="subDetail">
                        <div className="subDetailTop">
                            <h1 className="subNumber">Create New Sub-Consultant</h1>
                            <button onClick={handleCreate} className="createButton">Create</button>
                        </div>
                        <form className="subForm">
                            <div className="formGroup">
                                <label>Sub Number:</label>
                                <input type="text" name="number" value={sub.SC_number} onChange={handleChange} />
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Sub Name:</label>
                                    <input type="text" name="name" value={sub.SC_name} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Owner:</label>
                                    <input type="text" name="owner" value={sub.owner} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Type:</label>
                                    <input type="text" name="type" value={sub.type} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Address:</label>
                                    <input type="text" name="address" value={sub.address} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Postal Code:</label>
                                    <input type="text" name="postal_code" value={sub.postal_code} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>City:</label>
                                    <input type="text" name="city" value={sub.city} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Province:</label>
                                    <input type="text" name="province" value={sub.province} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Payment Type:</label>
                                    <input type="text" name="payment_type" value={sub.payment_type} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Tel 1:</label>
                                    <input type="text" name="tel_1" value={sub.tel_1} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Tel 2:</label>
                                    <input type="text" name="tel_2" value={sub.tel_2} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Direct Phone:</label>
                                    <input type="text" name="tel_d" value={sub.tel_d} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Email:</label>
                                    <input type="text" name="email" value={sub.email} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Web:</label>
                                    <input type="text" name="web" value={sub.web} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Notes:</label>
                                    <textarea name="notes" value={sub.notes} onChange={handleChange}></textarea>
                                </div>
                            </div>
                            <div className="formRow formRowBottom">
                                <div className="formGroup">
                                    <label>Bad Flag:</label>
                                    <select name="bad_flag" value={sub.bad_flag} onChange={handleChange}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
}

export default SubCreate;