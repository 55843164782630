import React from 'react';
import './headerLogin.css'; 
import image from '../ziyutec logo long.png';

class HeaderLogin extends React.Component {
    render() {
        return (
            <header className="HeaderLogin">
                <div className="logoLogin">
                    <a href="/">
                        <img src={image} alt="Company Logo" /> {}
                    </a>
                </div>
            </header>
        )}
    }

export default HeaderLogin;