import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from '../../misc/header';
import './search.css';
import SearchForm from '../../misc/searchform';

function Search() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const keyword = searchParams.get('keyword');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);

    const [sortField, setSortField] = useState('Project_Number');
    const [sortDirection, setSortDirection] = useState('asc');

    const [filterField, setFilterField] = useState(null);
    const [filterValue, setFilterValue] = useState(null);

    const filteredAndSortedResults = [...results]
    .filter(result => filterField === null || filterValue === '' || result[filterField] === filterValue)
    .sort((a, b) => {
      const aValue = parseFloat((a[sortField] || '').replace(/,/g, ''));
      const bValue = parseFloat((b[sortField] || '').replace(/,/g, ''));
  
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        if (a[sortField] < b[sortField]) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (a[sortField] > b[sortField]) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      }
    });

    useEffect(() => {
        if (keyword) {
          setLoading(true);
          const fetchData = async () => {
              try {
                  const response = await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/search', { KeyWordSearch: keyword });
                  setResults(response.data);
              } catch (error) {
                  console.error('An error occurred:', error);
              } finally {
                  setLoading(false);
              }
          };
  
          fetchData();
        }
      }, [keyword]);

      return (
        <>
            <Header />
            <SearchForm />
            {keyword && (
            <>
                    {loading ? (
                        <div className='Loading'>Loading...</div>
                    ) : (
                        <div>
                            <h1 className="searchResultsTitle">PROJECT SEARCH RESULTS FOR "{keyword}"</h1>
                            {Array.isArray(results) && (
                                <table className="myTable">
                                    <thead>
                                        <tr>
                                        <td className="boldText" width='7.5%' bgcolor='#86C4E7' onClick={() => {
                                            setSortField('Project_Number');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}>
                                            <button className="yipButtonStyle sortButton">
                                                <span style={{display: 'inline-block', width: '100%'}}>Project Number</span> 
                                                <span className='sortButtonSpan'> {sortField === 'Project_Number' ? (sortDirection === 'asc' ? '▲' : '▼') : '-'}</span>
                                            </button>
                                        </td>
                                        <td className="boldText" width='7.5%' bgcolor='#E0EFF8'>
                                            <button className="yipButtonStyle sortButton" onClick={() => {
                                                setSortField('Proposal_Number');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}>
                                                <span style={{display: 'inline-block', width: '100%'}}>Proposal Number </span>
                                                <span className='sortButtonSpan'> {sortField === 'Proposal_Number' ? (sortDirection === 'asc' ? '▲' : '▼') : '-'}</span>
                                            </button>
                                        </td>
                                        <td className="boldText" width='40%' bgcolor='#86C4E7'>
                                            <button className="yipButtonStyle sortButton" onClick={() => {
                                                setSortField('Project_Name');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}>
                                                <span style={{display: 'inline-block', width: '100%'}}>Project Name </span>
                                                <span className='sortButtonSpan'> {sortField === 'Project_Name' ? (sortDirection === 'asc' ? '▲' : '▼') : '-'}</span>
                                            </button>
                                        </td>
                                        <td className="boldText" width='10%' align='center' bgcolor='#E0EFF8'>
                                            <select className="customSelect" onChange={e => {
                                                setFilterField('Discipline');
                                                setFilterValue(e.target.value);
                                            }}>
                                                <option value="">Disciplines</option>
                                                {[...new Set(results.map(result => result.Discipline))].map(discipline => (
                                                <option value={discipline}>{discipline}</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td className="boldText" width='10%' align='center' bgcolor='#86C4E7'>
                                            <select className="customSelect" onChange={e => {
                                                setFilterField('DisciplineLead');
                                                setFilterValue(e.target.value);
                                            }}>
                                                <option value="">Discipline Leads</option>
                                                {[...new Set(results.map(result => result.DisciplineLead))].map(lead => (
                                                <option value={lead}>{lead}</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td className="boldText" width='5%' bgcolor='#E0EFF8'>
                                            <button className="yipButtonStyle sortButton" onClick={() => {
                                                setSortField('ContractValue');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}>
                                                <span style={{display: 'inline-block', width: '100%'}}>Contract Value</span>
                                                <span className='sortButtonSpan'>{sortField === 'ContractValue' ? (sortDirection === 'asc' ? '▲' : '▼') : '-'}</span>
                                            </button>
                                        </td>
                                        <td className="boldText" width='5%' bgcolor='#86C4E7'>
                                            <button className="yipButtonStyle sortButton" onClick={() => {
                                                setSortField('ProjectSpent');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}>
                                                <span style={{display: 'inline-block', width: '100%'}}>Budget Spent</span>
                                                <span className='sortButtonSpan'> {sortField === 'ProjectSpent' ? (sortDirection === 'asc' ? '▲' : '▼') : '-'} </span>
                                            </button>
                                        </td>
                                        <td className="boldText" width='5%' bgcolor='#E0EFF8'>
                                            <button className="yipButtonStyle sortButton" onClick={() => {
                                                setSortField('totalBudgetRemaining');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}>
                                                <span style={{display: 'inline-block', width: '100%'}}>Remaining Budget</span>
                                                <span className='sortButtonSpan'> {sortField === 'totalBudgetRemaining' ? (sortDirection === 'asc' ? '▲' : '▼') : '-'} </span>
                                            </button>
                                        </td>
                                        <td className="boldText" width='10%' align='center' bgcolor='#86C4E7'>
                                            <select className="customSelect" onChange={e => {
                                                setFilterField('_Status');
                                                setFilterValue(e.target.value);
                                            }}>
                                                <option value="">All Statuses</option>
                                                {[...new Set(results.map(result => result._Status))].map(lead => (
                                                <option value={lead}>{lead}</option>
                                                ))}
                                            </select>
                                        </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredAndSortedResults.map   (result => (
                                            <tr key={result.id}> 
                                                <td>
                                                    <a href={`/viewProject/${result.Project_Number}`} className='searchVPLink'>
                                                        {result.Project_Number}
                                                    </a>
                                                </td>
                                                <td>{result.Proposal_Number}</td>
                                                <td>{result.Project_Name}</td>
                                                <td>{result.Discipline}</td>
                                                <td>{result.DisciplineLead}</td>
                                                <td className="alignRight" >{result.ContractValue}</td>
                                                <td className="alignRight" >{result.ProjectSpent}</td>
                                                <td className="alignRight" style={{
                                                    backgroundColor: parseFloat(result.totalBudgetRemaining) > 0 ? 'rgb(185, 250, 190)' :
                                                                    parseFloat(result.totalBudgetRemaining) < 0 ? 'rgb(250, 180, 180)' :
                                                                    parseFloat(result.totalBudgetRemaining) === 0 ? 'rgb(255, 245, 175)' : 'rgb(255, 255, 255)'
                                                }}>
                                                    {result.totalBudgetRemaining}
                                                </td>
                                                <td>{result._Status}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default Search;
