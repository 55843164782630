import './newProject.css';
import Header from '../../misc/header.js';
import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import axios from 'axios';

function ProjectDetails() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [project, setProject] = useState({
        projectNumber: '',
        proposalNumber: '',
        status: 'Created',
        startDate: '',
        endDate: '',
        address: '',
        city: '',
        state: '',
        clientName: '',
        coPartnerName: '',
        coordinatorName: '',
        invoicing: '',
        scope: '',
        notes: ''
    });

    const [dropdownData, setDropdownData] = useState({
        sectors: [],
        categories: [],
        projectTypes: [],
        projectTypes2: [],
        execOffices: [],
        contractTypes: []
    });


    const STATUS_OPTIONS = ["Closed", "Cancelled", "Completed", "Created", "In Progress", "On Hold", "Standing offer", "Stop", "BP Submitted"];
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    
    // function isValidDate(dateStr) {
    //     if (dateStr === undefined || dateStr.trim() === '') {
    //         return true;
    //     }
    //     const date = new Date(dateStr); 
    //     return !isNaN(date.getTime()); 
    // }

    async function handleCreateNewProject() {
      try {
          console.log('Creating new project:', project);
  
          const response = await fetch('https://ziyutecerp-9re9z.ondigitalocean.app/api/createProject', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  projectNumber: project.projectNumber,
                  mainProjectData: {
                      Project_Number: project.projectNumber,
                      Starting_date: project.startDate,
                      Completion_date: project.endDate,
                      Proposal_Number: project.proposalNumber,
                      Project_Name: project.projectName,
                      Address: project.address,
                      Province: project.state,
                      City: project.city,
                      _Status: project.status,
                      Invoicing_as_per_payment_schedule: project.invoicing,
                      Project_Scope: project.scope,
                      Sector: project.sector,
                      Category: project.category,
                      Project_Type: project.projectType,
                      Project_Type_2: project.projectType2,
                      Exec_office: project.execOffice,
                      indicate_feature_project: project.feature,
                      Contract_Type: project.contractType,
                      Electrical_Contractor: project.elecContractor,
                      Construction_cost: project.contractCost,
                      Project_area_in_sqft: project.area,
                  },
                  customerData: {
                      Client_Name: project.clientName,
                      CO_Partner_Name: project.coPartnerName,
                  },
                  coordinatorData: {
                      Coordinator_Name: project.coordinatorName,
                  }
              }),
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const data = await response.json();
          console.log('New project created:', data);
  
          if (response.ok) { 
            window.location.href = `/viewProject/${project.projectNumber}`;
        } else {
            console.error('Failed to create project');

        }
      } catch (error) {
          console.error('Failed to create new project:', error);
      }
  }

  useEffect(() => {
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/getDropdownData`);
            const dropdownResponse = response.data; 
            console.log("Parsed dropdownResponse:", dropdownResponse);
            if (dropdownResponse.dropdowns) {
                const dropdownData = {
                    sectors: dropdownResponse.dropdowns.sectors || [],
                    categories: dropdownResponse.dropdowns.categories || [],
                    projectTypes: dropdownResponse.dropdowns.projectTypes || [],
                    projectTypes2: dropdownResponse.dropdowns.projectTypes2 || [],
                    execOffices: dropdownResponse.dropdowns.execOffices || [],
                    contractTypes: dropdownResponse.dropdowns.contractTypes || []
                };
                setDropdownData(dropdownData);
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
        setIsLoading(false);
    };

    fetchData();
  }, []);
    

    return (
        <div className={`project-detail ${isLoading ? 'loading' : ''}`}>
            <Header />
            {isLoading ? (
            <h1 className='Loading'>Loading...</h1>
        ) : (
            <>
            <div className="project-detail-top">
            <h1 className='project-detail-title'>
                        {isEditingTitle ? (
                            <input 
                                className="title-input-edit"
                                style={{color: project.projectName === '' ? 'black' : 'red'}}
                                type="text" 
                                value={project.projectName} 
                                onChange={(e) => {
                                    setProject({...project, projectName: e.target.value});
                                }} 
                            />
                        ) : (
                            <>
                                {project.projectName || 'PROJECT NAME'}
                                <FaEdit 
                                    className="icon-edit"
                                    onClick={() => setIsEditingTitle(!isEditingTitle)} 
                                />
                            </>
                        )}
                    </h1>
                <div className="save-button-container">
                    <button className="save-button" onClick={handleCreateNewProject}>
                        Create
                    </button>
                </div>
            </div>
            <div className="project-container">
                <div className="project-information">
                    <div className="form-row-1">
                        <label className='project-number'>Project Number: 
                        <input 
                            type="text" 
                            value={project.projectNumber} 
                            onChange={(e) => {
                                setProject({...project, projectNumber: e.target.value});
                            }} 
                            />
                            </label>
                        <label className='proposal-number'>Proposal Number:
                        <input 
                            type="text" 
                            value={project.proposalNumber} 
                            onChange={(e) => {
                                setProject({...project, proposalNumber: e.target.value});
                            }} 
                            />
                        </label>
                        <label className='status'>Status:
                            <select 
                                value={project.status} 
                                onChange={(e) => {
                                    setProject({...project, status: e.target.value});
                                }}
                            >
                                {STATUS_OPTIONS.map(status => (
                                    <option key={status} value={status}>
                                        {status}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="form-row-2">
                        <label className='date'>Start Date: 
                            {project.startDate ? (
                                <input 
                                    type="date" 
                                    value={project.startDate}
                                    onChange={(e) => {
                                        setProject({...project, startDate: e.target.value});
                                    }}
                                />
                            ) : (
                                <input 
                                    type="date"
                                    onChange={(e) => {
                                        setProject({...project, startDate: e.target.value});
                                    }}
                                />
                            )}
                        </label>
                        <label className='address'>Address: 
                        <input 
                            type="text" 
                            value={project.address} 
                            onChange={(e) => {
                                setProject({...project, address: e.target.value});
                            }} 
                            />
                        </label>
                        <label className='city'>City: 
                        <input 
                            type="text" 
                            value={project.city} 
                            onChange={(e) => {
                                setProject({...project, city: e.target.value});
                            }} 
                            />
                        </label>
                        <label className='state'>State: 
                        <input 
                            type="text" 
                            value={project.state} 
                            onChange={(e) => {
                                setProject({...project, state: e.target.value});
                            }} 
                            />
                        </label>
                    </div>
                    <div className="form-row-3">
                    <label className='date'>End Date: 
                        {project.endDate ? (
                            <input 
                                type="date" 
                                value={project.endDate}
                                onChange={(e) => {
                                    setProject({...project, endDate: e.target.value});
                                }}
                            />
                        ) : (
                            <input 
                                type="date"
                                onChange={(e) => {
                                    setProject({...project, endDate: e.target.value});
                                }}
                            />
                        )}
                    </label>
                        <label className='nameTag'>Client Name: <input 
                            type="text" 
                            value={project.clientName} 
                            onChange={(e) => {
                                setProject({...project, clientName: e.target.value});
                            }} 
                            />
                        </label>
                        <label className='nameTag'>Coordinator Name: 
                        <input 
                            type="text" 
                            value={project.coordinatorName} 
                            onChange={(e) => {
                                setProject({...project, coordinatorName: e.target.value});
                            }} 
                            />
                        </label>
                        <label className='nameTag'>C/O Partner Name: 
                        <input 
                            type="text" 
                            value={project.coPartnerName} 
                            onChange={(e) => {
                                setProject({...project, coPartnerName: e.target.value});
                            }} 
                            />
                        </label>
                    </div>
                    <div className="form-row-4">
                    <label className='Sector'>Sector: 
                        <select 
                            value={project.sector} 
                            onChange={(e) => {
                                setProject({...project, sector: e.target.value});
                            }}
                        >
                            {dropdownData.sectors.map(sector => (
                                <option key={sector} value={sector}>{sector}</option>
                            ))}
                        </select>
                    </label>
                    <label className='Category'>Category: 
                        <select 
                            value={project.category} 
                            onChange={(e) => {
                                setProject({...project, category: e.target.value});
                            }}
                        >
                            {dropdownData.categories.map(category => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                        </select>
                    </label>
                    <label>Project Type: 
                        <select 
                            value={project.projectType} 
                            onChange={(e) => {
                                setProject({...project, projectType: e.target.value});
                            }}
                        >
                            {dropdownData.projectTypes.map(projectType => (
                                <option key={projectType} value={projectType}>{projectType}</option>
                            ))}
                        </select>
                    </label>
                    <label> Project Type 2: 
                        <select 
                                value={project.projectType2} 
                            onChange={(e) => {
                                setProject({...project, projectType2: e.target.value});
                            }}
                        >
                            {dropdownData.projectTypes2.map(projectType2 => (
                                <option key={projectType2} value={projectType2}>{projectType2}</option>
                            ))}
                        </select>
                    </label>
                    <label className='contractType'>Contract Type: 
                        <select 
                            value={project.contractType} 
                            onChange={(e) => {
                                setProject({...project, contractType: e.target.value});
                            }}
                        >
                            {dropdownData.contractTypes.map(contractType => (
                                <option key={contractType} value={contractType}>{contractType}</option>
                            ))}
                        </select>
                    </label>
                    </div>
                    <div className='form-row-5'>
                        <label className='execOffice'>Executive Office: 
                            <select 
                                value={project.execOffice} 
                                onChange={(e) => {
                                    setProject({...project, execOffice: e.target.value});
                                }}
                            >
                                {dropdownData.execOffices.map(execOffice => (
                                    <option key={execOffice} value={execOffice}>{execOffice}</option>
                                ))}
                            </select>
                        </label>


                        {/*not currently in use*/}
                        {/* <label>Electrical Contractor: 
                            <input 
                                type="text" 
                                value={project.elecContractor} 
                                onChange={(e) => setProject({...project, elecContractor: e.target.value})}
                            />
                        </label>
                        <label>Construction Cost: 
                            <input 
                                type="text" 
                                value={project.contractCost} 
                                onChange={(e) => setProject({...project, contractCost: e.target.value})}
                            />
                        </label> */}

                        <label className='area'>Project Area (in sqft): 
                            <input
                                type="text" 
                                value={project.area} 
                                onChange={(e) => setProject({...project, area: e.target.value})}
                            />
                        </label>
                    </div>
                    <div className="invoicing">
                        <label>Invoicing: <textarea value={project.invoicing} onChange={(e) => setProject({...project, invoicing: e.target.value})} /></label>
                    </div>
                    <div className="scope">
                        <label>Scope: <textarea value={project.scope} onChange={(e) => setProject({...project, scope: e.target.value})} /></label>
                    </div>
                </div>
            </div>
        </>
        )}
        </div>
    );
  }



export default ProjectDetails;