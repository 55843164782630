import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import axios from 'axios';
import Header from '../../misc/header';
import ReactDOM from 'react-dom';
import './viewProject.css';

function ProjectDetails() {
    const { projectNumber } = useParams();
    const [isLoading, setIsLoading] = React.useState(false);
    const [project, setProject] = useState({
        projectNumber: '',
        proposalNumber: '',
        status: '',
        startDate: '',
        endDate: '',
        address: '',
        city: '',
        fee_rate_type: '',
        state: '',
        clientName: '',
        coPartnerName: '',
        coordinatorName: '',
        invoicing: '',
        scope: '',
        notes: ''
    });
    const [notes, setNotes] = useState([]);
    const [selectedNote, setSelectedNote] = useState(null);
    const [dropdownData, setDropdownData] = useState({
        sectors: [],
        categories: [],
        projectTypes: [],
        projectTypes2: [],
        execOffices: [],
        contractTypes: []
    });

    const [projectData, setProjectData] = useState({});
    const [processedBudgetDetails, setprocessedBudgetDetails] = useState({});
    const [budgetRateDetails, setBudgetRateDetails] = useState({});
    const [disciplineRates, setDisciplineRates] = useState({});
    const [disciplineLeads, setDisciplineLeads] = useState({});
    const [services, setServices] = useState({});
    // const [milestones, setMilestones] = useState({});

    const [originalSector, setOriginalSector] = useState('');
    const [originalCategory, setOriginalCategory] = useState('');
    const [originalProjectType, setOriginalProjectType] = useState('');
    const [originalProjectType2, setOriginalProjectType2] = useState('');
    const [originalContractType, setOriginalContractTypes] = useState('');
    const [originalExecOffice, setOriginalExecOffice] = useState('');

    const STATUS_OPTIONS = ["Closed", "Cancelled", "Completed", "Created", "In Progress", "On Hold", "Standing offer", "Stop", "BP Submitted"];
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [originalProject, setOriginalProject] = useState(null);

    // function isValidDate(dateStr) {
    //     if (dateStr === undefined || dateStr.trim() === '') {
    //         return true;
    //     }
    //     const date = new Date(dateStr);
    //     return !isNaN(date.getTime()); 
    // }

    const Modal = ({ children, onClose }) => {
        return ReactDOM.createPortal(
            <div className="modal-backdrop" onClick={onClose}>
                <div className="modal" onClick={(e) => e.stopPropagation()}>
                    New Note
                    {children}
                </div>
            </div>,
            document.body
        );
    };

    async function handleSave() {
        try {
            console.log('Saving project:', project);
    
            const response = await fetch(`https://ziyutecerp-9re9z.ondigitalocean.app/api/updateProjectDetails/${project.projectNumber}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mainProjectData: {
                        Project_Number: project.projectNumber,
                        Starting_date: project.startDate,
                        Completion_date: project.endDate,
                        Proposal_Number: project.proposalNumber,
                        Project_Name: project.projectName,
                        Address: project.address,
                        Province: project.state,
                        City: project.city,
                        fee_rate_type: project.fee_rate_type,
                        _Status: project.status,
                        Invoicing_as_per_payment_schedule: project.invoicing,
                        Project_Scope: project.scope,
                        Sector: project.sector,
                        Category: project.category,
                        Project_Type: project.projectType,
                        Project_Type_2: project.projectType2,
                        Exec_office: project.execOffice,
                        indicate_feature_project: project.feature,
                        Contract_Type: project.contractType,
                        Electrical_Contractor: project.elecContractor,
                        Construction_cost: project.contractCost,
                        Project_area_in_sqft: project.area,
                    },
                    customerData: {
                        Client_Name: project.clientName,
                        CO_Partner_Name: project.coPartnerName,
                    },
                    coordinatorData: {
                        Coordinator_Name: project.coordinatorName,
                    }
                }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            console.log('Project saved:', data);

            window.location.reload();

        } catch (error) {
            console.error('Failed to save project:', error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/getProject/${projectNumber}`);
                if (response.data.projectDetails) {
                    // console.log('Full Response Data:', response.data);
                    const projectData = response.data.projectDetails;
                    console.log('Project Data:', projectData);
                    setProjectData(projectData);
    
                    const processedBudgetDetails = response.data.processedBudgetDetails;
                    // console.log('processed budget Details:', processedBudgetDetails);
                    setprocessedBudgetDetails(processedBudgetDetails);
    
                    const budgetRateDetails = response.data.budgetDetails.budgetDetails;
                    // console.log('Budget Rate Details:', budgetRateDetails);
                    setBudgetRateDetails(budgetRateDetails);
    
                    const disciplineRates = response.data.budgetDetails.disciplines;
                    // console.log('Discipline Rates:', disciplineRates);
                    setDisciplineRates(disciplineRates);
    
                    const disciplineLeads = response.data.disciplineLeads;    
                    // console.log('Discipline Leads:', disciplineLeads);
                    setDisciplineLeads(disciplineLeads);
    
                    const services = response.data.services;
                    console.log('Services:', services);
                    setServices(services);
    
                    // const milestones = response.data.milestones;
                    // console.log('Milestones:', milestones);
                    // setMilestones(milestones);

                    
                    const newProject = {
                        projectNumber: projectData.Project_Number || '',
                        proposalNumber: projectData.Proposal_Number || '',
                        projectName: projectData.Project_Name || '',
                        status: projectData.Status || '',
                        startDate: projectData.Starting_date ? projectData.Starting_date.split('T')[0] : '',
                        endDate: projectData.Completion_date ? projectData.Completion_date.split('T')[0] : '',
                        address: projectData.Address || '',
                        city: projectData.City || '',
                        state: projectData.State || '',
                        fee_rate_type: projectData.fee_rate_type,
                        budget: projectData.Design_budget || '',
                        clientName: projectData.Client_Name || '',
                        coPartnerName: projectData.CO_Partner_Name || '',
                        coordinatorName: projectData.Coordinator_Name || '',
                        invoicing: projectData.Invoicing || '',
                        scope: projectData.Scope || '',
                        feature: projectData.Feature_Project || '',
                        elecContractor: projectData.Electrical_Contractor || '',
                        contractCost: projectData.Construction_cost || '',
                        area: projectData.Area || '',
                        notes: projectData.Notes || '',
                        sector: projectData.Sector || '',
                        category: projectData.Category || '',
                        projectType: projectData.Project_Type || '',
                        projectType2: projectData.Project_Type_2 || '',
                        contractType: projectData.Contract_Type || '',
                        execOffice: projectData.Executive_Office|| '',
                    };
                    setProject(newProject);
                    setOriginalProject(newProject);
                    setOriginalSector(projectData.Sector || '');
                    setOriginalCategory(projectData.Category || '');
                    setOriginalProjectType(projectData.Project_Type || '');
                    setOriginalProjectType2(projectData.Project_Type_2 || '');
                    setOriginalContractTypes(projectData.Contract_Type || '');

                    setOriginalExecOffice(projectData.Executive_Office || '');
              }


                if (response.data.dropdowns) {
                    const dropdownData = {
                        sectors: response.data.dropdowns.sectors || [],
                        categories: response.data.dropdowns.categories || [],
                        projectTypes: response.data.dropdowns.projectTypes || [],
                        projectTypes2: response.data.dropdowns.projectTypes2 || [],
                        execOffices: response.data.dropdowns.execOffices || [],
                        contractTypes: response.data.dropdowns.contractTypes || []
                    };
                    setDropdownData(dropdownData);
                }

                setNotes(response.data.notes || []);
                if (response.data.notes && response.data.notes.length > 0) {
                    setSelectedNote(response.data.notes[0]);
                }
            } catch (error) {
                console.error('Failed to fetch project data:', error);
            }
            setIsLoading(false);
        };

        fetchData();
    }, [projectNumber]);

    const serviceFieldMapping = {
        "ASHRAE / NECB": "Field1_ASHRAE",
        "Audio/Video": "Field2_Audio_Video",
        "BAS": "Field3_BAS",
        "Communication": "Field4_Communication",
        "Code Consulting": "Field5_Code_Consulting",
        "Certification/Report": "Field6_Certification_Report",
        "Electrical Service Upgrading": "Field7_Electrical_Service_Upgrading",
        "EV Charging": "Field8_EV_Charging",
        "Fire Alarm": "Field9_Fire_Alarm",
        "Fire Protection": "Field10_Fire_Protection",
        "Generator": "Field11_Generator",
        "Hazardous Classification": "Field12_Hazardous_Classification",
        "Heat Trace": "Field13_Heat_Trace",
        "HVAC": "Field14_HVAC",
        "HV Clearance Letter": "Field15_HV_Clearance_Letter",
        "I and C": "Field16_I_and_C",
        "Lighting / Controls": "Field17_Lighting_and_Controls",
        "Mulit-Fields": "Field18_Mulit_Fields",
        "Nurse Call": "Field19_Nurse_Call",
        "PA": "Field20_PA",
        "Plumbing": "Field21_Plumbing",
        "Power Study": "Field22_Power_Study",
        "Power": "Field23_Power",
        "Schedule A/B/CA/CB": "Field24_Schedule_A_B_CA_CB",
        "Security": "Field25_Security",
        "Solar Panel": "Field26_Solar_Panel",
        "Stamping": "Field27_Stamping",
        "Street/Site lighting": "Field28_Street_Site_lighting",
        "Others": "Field29_Others",
        "Peer Review": "Field30_Peer_Review",
        "ESA Application": "Field31_ESA_Application",
        "Commission": "Field32_Commission"
    };

    const getActiveServices = (details, services) => {
        if (!Array.isArray(services)) return [];
        return services.filter(service => {
            const fieldKey = serviceFieldMapping[service];
            return details[fieldKey] === "on";
        });
    };

    const activeServices = getActiveServices(projectData, services);
    console.log('Active Services:', activeServices);

    const disciplineNames = ['Architectural', 'Building Envelope', 'CAD/BIM', 'Civil', 'Code Consultant', 'Commissioning', 'Cost Estimate', 'Electrical', 'Energy/Sustainability', 'Environmental', 'Geotechnical', 'Interior Design', 'Landscape', 'Mechanical', 'Project Management', 'Structural', 'Others', 'E_Drafting', 'Stamping', 'Construction_Admin'];     
    
    function DisciplineSection({ disciplineRates = {}, budgetRateDetails }) {
        const nonZeroDisciplines = Object.entries(disciplineRates).filter(([disciplineName, rate]) => {
            const numericRate = Number(rate);
            return disciplineName !== 'SN' && !isNaN(numericRate) && numericRate > 0;
        });
    
        function fetchDisciplineLead(disciplineNumber, disciplineLeads) {
            const columnName = `Discipline_Lead_${disciplineNumber}`;
            return disciplineLeads[columnName] || 'Not assigned';
        }
    
        const leads = {};
        for (const [disciplineName] of nonZeroDisciplines) {
            const disciplineNumber = disciplineNames.indexOf(disciplineName) + 1;
            console.log('Discipline Number:', disciplineNumber);
            const leadName = fetchDisciplineLead(disciplineNumber, disciplineLeads);
            leads[disciplineName] = leadName;
        }

        console.log('Leads:', leads);

        return (
            <div className="discipline-section">
                {nonZeroDisciplines.map(([disciplineName, rate]) => {
                    const budgetAssignedValue = (rate / 100) * processedBudgetDetails.netBudget;
                    const totalSpentInDiscipline = budgetRateDetails.reduce((acc, detail) => {
                        if (detail.discipline === disciplineName) {
                            const workHours = Number(detail.workHours) || 0;
                            const serviceFeeRate = Number(detail.serviceFeeRate) || 0;
                            const budgetUsed = workHours * serviceFeeRate; 
                            return acc + budgetUsed; 
                        }
                        return acc;
                    }, 0);
                    
                    const remainingBudget = budgetAssignedValue - totalSpentInDiscipline; 


                    const validDetails = budgetRateDetails.filter(detail => 
                        detail.discipline === disciplineName && detail.staffName && detail.serviceFeeRate && detail.workHours
                    );
    
                    return (
                        
                        <div key={disciplineName}>

                            <table className="full-width-table">
                                <thead>
                                <tr>
                                    <th className="table-header">Discipline Name</th>
                                    <th className="table-header">Budget Assigned Rate</th>
                                    <th className="table-header">Budget Assigned Value</th>
                                    <th className="table-header">Remaining Budget</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{disciplineName}</td>
                                        <td>{rate}%</td>
                                        <td>${budgetAssignedValue.toFixed(2)}</td>
                                        <td>${remainingBudget.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="full-width-table-2">
                                <thead>
                                    <tr>
                                        <th className="table-header">Discipline</th>
                                        <th className="table-header">Position</th>
                                        <th className="table-header">Staff Name</th>
                                        <th className="table-header">Hourly Rate</th>
                                        <th className="table-header">Used Hours</th>
                                        <th className="table-header">Budget Used</th>
                                        <th className="table-header"h>Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {validDetails.map((detail, index) => {
                                        const isLead = leads[detail.discipline] === detail.staffName;
                                        return (
                                            <tr key={index} style={{ backgroundColor: isLead ? '#FFF0B3' : 'transparent' }}>
                                                <td>{detail.discipline}</td>
                                                <td>{detail.position}</td>
                                                <td>{detail.staffName}</td>
                                                <td>${detail.serviceFeeRate}</td>
                                                <td>{detail.workHours}</td>
                                                <td>${(detail.workHours * detail.serviceFeeRate).toFixed(2)}</td>
                                                <td>{detail.note}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <div className={`project-detail ${isLoading ? 'loading' : ''}`}>
            <Header />
            {isLoading ? (
            <h1 className='Loading'>Loading...</h1>
        ) : (
            <>
            <div className="project-detail-top">
            <h1 className='project-detail-title'>
                {isEditingTitle ? (
                    <input 
                        className="title-input-edit"
                        style={{color: project.projectName === originalProject.projectName ? 'black' : 'red'}}
                        type="text" 
                        value={project.projectName} 
                        onChange={(e) => {
                            setProject({...project, projectName: e.target.value});
                        }} 
                    />
                ) : (
                    <>
                        {project.projectName || 'PROJECT NAME'}
                        <FaEdit 
                            className="icon-edit"
                            onClick={() => setIsEditingTitle(!isEditingTitle)} 
                        />
                    </>
                )}
            </h1>
                <div className="save-button-container">
                    <button className="save-button" onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
            <div className="project-container">
                <div className="project-information">
                    <div className="form-row-1">
                        <label className='project-number'>Project Number: 
                        <input 
                            type="text" 
                            value={project.projectNumber} 
                            onChange={(e) => {
                                setProject({...project, projectNumber: e.target.value});
                                e.target.style.color = e.target.value === originalProject.projectNumber ? 'black' : 'red';
                            }} 
                            />
                            </label>
                        <label className='proposal-number'>Proposal Number:
                        <input 
                            type="text" 
                            value={project.proposalNumber} 
                            onChange={(e) => {
                                setProject({...project, proposalNumber: e.target.value});
                                e.target.style.color = e.target.value === originalProject.proposalNumber ? 'black' : 'red';
                            }} 
                            />
                        </label>
                        <label className='status'>Status:
                            <select 
                                value={project.status} 
                                onChange={(e) => {
                                    setProject({...project, status: e.target.value});
                                    e.target.style.color = e.target.value === originalProject.status ? 'black' : 'red';
                                }}
                            >
                                {STATUS_OPTIONS.map(status => (
                                    <option key={status} value={status}>
                                        {status}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="form-row-2">
                        <label className='date'>Start Date: 
                        <input 
                            type="date" 
                            value={project.startDate} 
                            onChange={(e) => {
                                const newValue = e.target.value;
                                let isValidDate = newValue && !isNaN(new Date(newValue).getTime());
                            
                                if (isValidDate || newValue === '') {
                                    let formattedNewValue = isValidDate ? new Date(newValue).toISOString().split('T')[0] : '';
                                    let originalValue = originalProject.startDate && !isNaN(new Date(originalProject.startDate).getTime()) 
                                                        ? new Date(originalProject.startDate).toISOString().split('T')[0] 
                                                        : '';
                            
                                    setProject({...project, startDate: newValue});
                            
                                    e.target.style.color = (formattedNewValue === originalValue || (!newValue && !originalProject.startDate)) ? 'black' : 'red';
                                } else {
                                    console.error('Invalid date input');
                                }
                            }}
                        />
                    </label>
                        <label className='address'>Address: 
                        <input 
                            type="text" 
                            value={project.address} 
                            onChange={(e) => {
                                setProject({...project, address: e.target.value});
                                e.target.style.color = e.target.value === originalProject.address ? 'black' : 'red';
                            }} 
                            />
                        </label>
                        <label className='city'>City: 
                        <input 
                            type="text" 
                            value={project.city} 
                            onChange={(e) => {
                                setProject({...project, city: e.target.value});
                                e.target.style.color = e.target.value === originalProject.city ? 'black' : 'red';
                            }} 
                            />
                        </label>
                        <label className='state'>State: 
                        <input 
                            type="text" 
                            value={project.state} 
                            onChange={(e) => {
                                setProject({...project, state: e.target.value});
                                e.target.style.color = e.target.value === originalProject.state ? 'black' : 'red';
                            }} 
                            />
                        </label>
                    </div>
                    <div className="form-row-3">
                        <label className='date'>End Date: 
                            <input 
                                type="date" 
                                value={project.endDate} 
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    let isValidDate = newValue && !isNaN(new Date(newValue).getTime());
                                
                                    if (isValidDate || newValue === '') {
                                        let formattedNewValue = isValidDate ? new Date(newValue).toISOString().split('T')[0] : '';
                                        let originalValue = originalProject.endDate && !isNaN(new Date(originalProject.endDate).getTime()) 
                                                            ? new Date(originalProject.endDate).toISOString().split('T')[0] 
                                                            : '';
                                
                                        setProject({...project, endDate: newValue});
                                
                                        e.target.style.color = (formattedNewValue === originalValue || (!newValue && !originalProject.endDate)) ? 'black' : 'red';
                                    } else {
                                        console.error('Invalid date input');
                                    }
                                }}
                            />
                        </label>
                        <label className='nameTag'>Client Name: <input 
                            type="text" 
                            value={project.clientName} 
                            onChange={(e) => {
                                setProject({...project, clientName: e.target.value});
                                e.target.style.color = e.target.value === originalProject.clientName ? 'black' : 'red';
                            }} 
                            />
                        </label>
                        <label className='nameTag'>Coordinator Name: 
                        <input 
                            type="text" 
                            value={project.coordinatorName} 
                            onChange={(e) => {
                                setProject({...project, coordinatorName: e.target.value});
                                e.target.style.color = e.target.value === originalProject.coordinatorName ? 'black' : 'red';
                            }} 
                            />
                        </label>
                        <label className='nameTag'>C/O Partner Name: 
                        <input 
                            type="text" 
                            value={project.coPartnerName} 
                            onChange={(e) => {
                                setProject({...project, coPartnerName: e.target.value});
                                e.target.style.color = e.target.value === originalProject.coPartnerName ? 'black' : 'red';
                            }} 
                            />
                        </label>
                    </div>
                    <div className="form-row-4">
                    <label className='Sector'>Sector: 
                        <select 
                            value={project.sector} 
                            onChange={(e) => {
                                setProject({...project, sector: e.target.value});
                                e.target.style.color = e.target.value === originalSector ? 'black' : 'red';
                            }}
                        >
                            {dropdownData.sectors.map(sector => (
                                <option key={sector} value={sector}>{sector}</option>
                            ))}
                        </select>
                    </label>
                    <label className='Category'>Category: 
                        <select 
                            value={project.category} 
                            onChange={(e) => {
                                setProject({...project, category: e.target.value});
                                e.target.style.color = e.target.value === originalCategory ? 'black' : 'red';
                            }}
                        >
                            {dropdownData.categories.map(category => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                        </select>
                    </label>
                    <label>Project Type: 
                        <select 
                            value={project.projectType} 
                            onChange={(e) => {
                                setProject({...project, projectType: e.target.value});
                                e.target.style.color = e.target.value === originalProjectType ? 'black' : 'red';
                            }}
                        >
                            {dropdownData.projectTypes.map(projectType => (
                                <option key={projectType} value={projectType}>{projectType}</option>
                            ))}
                        </select>
                    </label>
                    <label> Project Type 2: 
                        <select 
                                value={project.projectType2} 
                            onChange={(e) => {
                                setProject({...project, projectType2: e.target.value});
                                e.target.style.color = e.target.value === originalProjectType2 ? 'black' : 'red';
                            }}
                        >
                            {dropdownData.projectTypes2.map(projectType2 => (
                                <option key={projectType2} value={projectType2}>{projectType2}</option>
                            ))}
                        </select>
                    </label>
                    <label className='contractType'>Contract Type: 
                        <select 
                            value={project.contractType} 
                            onChange={(e) => {
                                setProject({...project, contractType: e.target.value});
                                e.target.style.color = e.target.value === originalContractType ? 'black' : 'red';
                            }}
                        >
                            {dropdownData.contractTypes.map(contractType => (
                                <option key={contractType} value={contractType}>{contractType}</option>
                            ))}
                        </select>
                    </label>
                    </div>
                    <div className='form-row-5'>
                        <label className='execOffice'>Executive Office: 
                            <select 
                                value={project.execOffice} 
                                onChange={(e) => {
                                    setProject({...project, execOffice: e.target.value});
                                    e.target.style.color = e.target.value === originalExecOffice ? 'black' : 'red';
                                }}
                            >
                                {dropdownData.execOffices.map(execOffice => (
                                    <option key={execOffice} value={execOffice}>{execOffice}</option>
                                ))}
                            </select>
                        </label>


                        {/*not currently in use*/}
                        {/* <label>Electrical Contractor: 
                            <input 
                                type="text" 
                                value={project.elecContractor} 
                                onChange={(e) => setProject({...project, elecContractor: e.target.value})}
                            />
                        </label>
                        <label>Construction Cost: 
                            <input 
                                type="text" 
                                value={project.contractCost} 
                                onChange={(e) => setProject({...project, contractCost: e.target.value})}
                            />
                        </label> */}

                        <label className='area'>Project Area (in sqft): 
                            <input
                                type="text" 
                                value={project.area} 
                                onChange={(e) => setProject({...project, area: e.target.value})}
                            />
                        </label>
                    </div>
                    <div className="invoicing">
                        <label>Invoicing: <textarea value={project.invoicing} onChange={(e) => setProject({...project, invoicing: e.target.value})} /></label>
                    </div>
                    <div className="scope">
                        <label>Scope: <textarea value={project.scope} onChange={(e) => setProject({...project, scope: e.target.value})} /></label>
                    </div>
                    <div className='budgetTable'>
                        <h4>Budget Structure</h4>
                        <table border="1" width="100%" align="center">
                            <tr>
                                <td>
                                    <div className="field-container">
                                        <label htmlFor="feeRateType">Fee Rate Type</label>
                                        <select 
                                            id="feeRateType"
                                            value={project.fee_rate_type} 
                                            onChange={(e) => {
                                                setProject({...project, fee_rate_type: e.target.value});
                                                e.target.style.color = e.target.value === originalProject.fee_rate_type ? 'black' : 'red';
                                            }} >
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div className="field-container">
                                        <label htmlFor="designBudget">Design Budget</label>
                                        <input 
                                            id="designBudget"
                                            type="text" 
                                            value={project.budget || '0'} 
                                            readOnly 
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="field-container">
                                        <label htmlFor="totalPOMEPAmount">Total POMEP Amount</label>
                                        <input 
                                            id="totalPOMEPAmount"
                                            type="text" 
                                            value={processedBudgetDetails.totalPOMEPAmount || '0'} 
                                            onChange={(e) => setprocessedBudgetDetails({...processedBudgetDetails, totalPOMEPAmount: e.target.value})}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="field-container">
                                        <label htmlFor="totalPOSCPAmount">Total POSCP Amount</label>
                                        <input 
                                            id="totalPOSCPAmount"
                                            type="text" 
                                            value={processedBudgetDetails.totalPOSCPAmount || '0'} 
                                            onChange={(e) => setprocessedBudgetDetails({...processedBudgetDetails, totalPOSCPAmount: e.target.value})}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="field-container">
                                        <label htmlFor="totalCOAmount">Total CO Amount</label>
                                        <input 
                                            id="totalCOAmount"
                                            type="text" 
                                            value={processedBudgetDetails.totalCOAmount || '0'} 
                                            onChange={(e) => setprocessedBudgetDetails({...processedBudgetDetails, totalCOAmount: e.target.value})}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="field-container">
                                        <label htmlFor="disbursement">Disbursement (5%)</label>
                                        <input 
                                            id="disbursement"
                                            type="text" 
                                            value={processedBudgetDetails.disbursement || '0'} 
                                            onChange={(e) => setprocessedBudgetDetails({...processedBudgetDetails, disbursement: e.target.value})}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="field-container">
                                        <label htmlFor="netExecutionBudget">Net Execution Budget</label>
                                        <input 
                                            id="netExecutionBudget"
                                            type="text" 
                                            value={processedBudgetDetails.netBudget || '0'} 
                                            onChange={(e) => setprocessedBudgetDetails({...processedBudgetDetails, netBudget: e.target.value})}
                                        />
                                    </div>
                                </td>
                                {/* <td>
                                    <div className="field-container">
                                        <label htmlFor="totalBudgetRemaining">Total Budget Remaining($)</label>
                                        <input 
                                            id="totalBudgetRemaining"
                                            type="text" 
                                            value={processedBudgetDetails.totalBudgetRemaining || '0'} 
                                            onChange={(e) => setprocessedBudgetDetails({...processedBudgetDetails, totalBudgetRemaining: e.target.value})}
                                        />
                                    </div>
                                </td> */}
                                <td>
                                    <div className="field-container">
                                        <label htmlFor="projectProfit">Project Profit</label>
                                        <input 
                                            id="projectProfit"
                                            type="text" 
                                            value={
                                                (
                                                    project.budget + 
                                                    processedBudgetDetails.totalCOAmount - 
                                                    processedBudgetDetails.totalPOMEPAmount - 
                                                    processedBudgetDetails.totalPOSCPAmount - 
                                                    processedBudgetDetails.totalSpent || 0
                                                ).toFixed(2)
                                            }
                                            onChange={(e) => setprocessedBudgetDetails({...processedBudgetDetails, totalSpent: e.target.value})}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='disciplines-section'>
                    <DisciplineSection
                        disciplineRates={disciplineRates}
                        project={project}
                        budgetRateDetails={budgetRateDetails}
                    />
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                        <div style={{ width: '20px', height: '20px', backgroundColor: '#FFF0B3', marginRight: '5px', border: '1px solid black' }}></div>
                        <span>Discipline Lead</span>
                    </div>
                    <div className="active-services">
                        <h3>Active Services</h3>
                        <ul>
                            {activeServices.map(service => (
                                <li key={service}>{service.replace(/Field\d+_/, '').replace(/_/g, ' ')}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                </div>
                
                <div className="notes-section">
                    <h2>Notes</h2>
                    <NotesComponent notes={notes} selectedNote={selectedNote} setSelectedNote={setSelectedNote} />
                </div>
            </div>
        </>
        )}
        </div>
    );


    async function addNoteToDatabase(noteContent) {
        const staffName = localStorage.getItem('staffName'); 
        try {
            const response = await fetch('https://ziyutecerp-9re9z.ondigitalocean.app/api/addNote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    project_number: projectNumber,
                    _notes: noteContent,
                    Writer: staffName, 
                    date_time: new Date().toISOString(), 

                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Note added:', data);
        } catch (error) {
            console.error('Failed to add note:', error);
        }
    }

    async function updateNoteInDatabase(noteId, noteContent) {
        const staffName = localStorage.getItem('staffName'); 
        try {
            const response = await fetch(`https://ziyutecerp-9re9z.ondigitalocean.app/api/updateNote/${noteId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _notes: noteContent,
                    Writer: staffName, 
                    date_time: new Date().toISOString(), 
                }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            console.log('Note updated:', data);
        } catch (error) {
            console.error('Failed to update note:', error);
        }
    }

    async function deleteNoteFromDatabase(noteId) {
        try {
            const response = await fetch(`https://ziyutecerp-9re9z.ondigitalocean.app/api/deleteNote/${noteId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Note deleted');
        } catch (error) {
            console.error('Failed to delete note:', error);
        }
    }


    function NotesComponent({ notes, selectedNote, setSelectedNote}) {
        const [noteContent, setNoteContent] = React.useState('');
        const [refreshKey, setRefreshKey] = React.useState(0);

        const [showAddNoteModal, setShowAddNoteModal] = useState(false);
        const [newNoteContent, setNewNoteContent] = useState('');

        const fetchNotes = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/getProject/${projectNumber}`);
                if (response.data.notes) {
                    setNotes(response.data.notes);
                    if (response.data.notes.length > 0) {
                        setSelectedNote(response.data.notes[0]);
                    }
                }
            } catch (error) {
                console.error('Failed to fetch notes:', error);
            }
            setIsLoading(false);
        };
    
    
        const handlePublishNote = async () => {
            await addNoteToDatabase(newNoteContent);
            setShowAddNoteModal(false);
            setNewNoteContent('');
            await fetchNotes();
            setRefreshKey(prevKey => prevKey + 1);
        };
    
        const handleCancelNote = () => {
            setShowAddNoteModal(false);
            setNewNoteContent(''); 
        };
    
        React.useEffect(() => {
            if (selectedNote) {
                setNoteContent(selectedNote._notes);
            }
        }, [selectedNote, refreshKey]); 
    
        // const refreshNotes = () => setRefreshKey(prevKey => prevKey + 1); 
    
        const handleUpdateNote = async () => {
            if (selectedNote) {
                await updateNoteInDatabase(selectedNote.SN, noteContent);
                await fetchNotes();
                setRefreshKey(prevKey => prevKey + 1);
            }
        };
    
        const handleDeleteNote = async () => {
            if (selectedNote) {
                await deleteNoteFromDatabase(selectedNote.SN);
                setSelectedNote(null);
                await fetchNotes();
                setRefreshKey(prevKey => prevKey + 1);
            }
        };
    
        const formatDate = (dateString) => {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            return new Date(dateString).toLocaleDateString(undefined, options);
        }

        return (
            <div key={refreshKey}>
                <div className="notes-container-top">
                    <button className="add-note-button" onClick={() => setShowAddNoteModal(true)}>Add Note</button>
                        {showAddNoteModal && (
                            <Modal onClose={handleCancelNote}>
                                <textarea 
                                    value={newNoteContent} 
                                    onChange={(e) => setNewNoteContent(e.target.value)}
                                />
                                <div className='modal-buttons'>
                                    <button onClick={handlePublishNote}>Publish</button>
                                    <button onClick={handleCancelNote}>Cancel</button>
                                </div>
                            </Modal>
                        )}

                    <div className="notes-container">
                        <div className="notes-tabs">
                            {notes.map((note, index) => (
                                <div
                                    key={note.SN}
                                    className={`note-tab ${selectedNote && selectedNote.SN === note.SN ? 'selected' : ''}`}
                                    onClick={() => setSelectedNote(note)}
                                >
                                    <div>{formatDate(note.date_time)}</div>
                                    <div>{note.Writer}</div>
                                </div>
                            ))}
                        </div>
                        <div className="note-content">
                            {selectedNote && (
                                <>
                                    <div className="note-header">
                                        <div>
                                            <div><strong>Date:</strong> {formatDate(selectedNote.date_time)}</div>
                                            <div><strong>Writer:</strong> {selectedNote.Writer}</div>
                                        </div>
                                        <button onClick={handleUpdateNote} disabled={!selectedNote}>Update Note</button>
                                    </div>
                                    <textarea 
                                        value={noteContent} 
                                        onChange={(e) => setNoteContent(e.target.value)}
                                    />
                                    <div className="note-actions">
                                        <button onClick={handleDeleteNote} disabled={!selectedNote}>Delete Note</button>
                                    </div>
                                </>
                            )} 
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default ProjectDetails;