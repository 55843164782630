import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../../misc/header';
import './contactView.css';

function ContactCreate() {
    const navigate = useNavigate();
    const [contact, setContact] = useState({
        C_C_Number: '',
        C_C_Name: '',
        C_C_Phone_1: '',
        C_C_Phone_2: '',
        C_C_Email_W: '',
        C_C_Email_P: '',
        C_C_Note: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContact(prevContact => ({
            ...prevContact,
            [name]: value
        }));
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        try {
            console.log('Creating contact:', contact);
            await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/createContact', contact);
            navigate('/contacts/contactList');
        } catch (error) {
            console.error('Error creating contact:', error);
        }
    };

    return (
        <>
            <Header />
            <div className="contactViewContainer">
                <div className="contactDetail">
                    <div className="contactDetailTop">
                        <h1 className="contactNumber">Create New Contact</h1>
                        <button onClick={handleCreate} className="updateButton">Create</button>
                    </div>
                    <form className="contactForm">
                        <div className="formRow">
                            <div className="formGroup">
                                <label>Contact Number:</label>
                                <input type="text" name="C_C_Number" value={contact.C_C_Number} onChange={handleChange} />
                            </div>
                            <div className="formGroup">
                                <label>Name:</label>
                                <input type="text" name="C_C_Name" value={contact.C_C_Name} onChange={handleChange} />
                            </div>
                            <div className="formGroup">
                                <label>Phone 1:</label>
                                <input type="text" name="C_C_Phone_1" value={contact.C_C_Phone_1} onChange={handleChange} />
                            </div>
                            <div className="formGroup">
                                <label>Phone 2:</label>
                                <input type="text" name="C_C_Phone_2" value={contact.C_C_Phone_2} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="formRow">
                            <div className="formGroup">
                                <label>Email (Work):</label>
                                <input type="text" name="C_C_Email_W" value={contact.C_C_Email_W} onChange={handleChange} />
                            </div>
                            <div className="formGroup">
                                <label>Email (Personal):</label>
                                <input type="text" name="C_C_Email_P" value={contact.C_C_Email_P} onChange={handleChange} />
                            </div>
                            <div className="formGroup">
                                <label>Notes:</label>
                                <textarea name="C_C_Note" value={contact.C_C_Note} onChange={handleChange}></textarea>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ContactCreate;