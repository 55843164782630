import Header from '../../../misc/header.js';
import React from 'react';
import './employeeDirectory.css';

const employeeDirectory = () => {
    return (
        <div>
            <Header />
            <div className="container">
                <div className="left-buttons">
                    <a href="/hr/employees/new" className="button">New Employee</a>
                    <a href="/hr/employees/personalProfile" className="button">Personal Profile</a>
                    <a href="/hr/employees/directory" className="button">Employee Directory</a>
                    <a href="/hr/employees/records" className="button">Complete Employee Records</a>
                </div>
                <div className='middle-buttons'>
                </div>
                <div className='right-buttons'> 
                </div>
            </div>
         </div>
    );
};

export default employeeDirectory;