import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './vendorSettings.css';
import Header from '../../misc/header';

function PurchaseTypeManager() {
    const [mepTypes, setMepTypes] = useState([]);
    const [scpTypes, setScpTypes] = useState([]);
    const [newMepType, setNewMepType] = useState({ typeName: '', notes: '' });
    const [newScpType, setNewScpType] = useState({ typeName: '', notes: '' });

    useEffect(() => {
        fetchPurchaseTypes();
    }, []);

    const fetchPurchaseTypes = async () => {
        try {
            const mepResponse = await axios.get('https://ziyutecerp-9re9z.ondigitalocean.app/api/mep-purchase-types');
            const scpResponse = await axios.get('https://ziyutecerp-9re9z.ondigitalocean.app/api/scp-purchase-types');
            setMepTypes(mepResponse.data);
            setScpTypes(scpResponse.data);
        } catch (error) {
            console.error('Failed to fetch purchase types', error);
        }
    };

    const handleAddMepType = async () => {
        try {
            await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/mep-purchase-types', newMepType);
            setNewMepType({ typeName: '', notes: '' });
            fetchPurchaseTypes();
        } catch (error) {
            console.error('Failed to add MEP purchase type', error);
        }
    };

    const handleAddScpType = async () => {
        try {
            await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/scp-purchase-types', newScpType);
            setNewScpType({ typeName: '', notes: '' });
            fetchPurchaseTypes();
        } catch (error) {
            console.error('Failed to add SCP purchase type', error);
        }
    };

    const handleDeleteMepType = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this MEP purchase type?');
        if (confirmDelete) {
            try {
                await axios.delete(`https://ziyutecerp-9re9z.ondigitalocean.app/api/mep-purchase-types/${id}`);
                fetchPurchaseTypes();
            } catch (error) {
                console.error('Failed to delete MEP purchase type', error);
            }
        }
    };

    const handleDeleteScpType = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this SCP purchase type?');
        if (confirmDelete) {
            try {
                await axios.delete(`https://ziyutecerp-9re9z.ondigitalocean.app/api/scp-purchase-types/${id}`);
                fetchPurchaseTypes();
            } catch (error) {
                console.error('Failed to delete SCP purchase type', error);
            }
        }
    };

    return (
        <>
            <Header />
            <div className="purchase-type-manager">
                <h2>MEP Purchase Type Setup</h2>
                <div className="purchase-type-form">
                    <input
                        type="text"
                        placeholder="Purchase Type"
                        value={newMepType.typeName}
                        onChange={(e) => setNewMepType({ ...newMepType, typeName: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Notes"
                        value={newMepType.notes}
                        onChange={(e) => setNewMepType({ ...newMepType, notes: e.target.value })}
                    />
                    <button onClick={handleAddMepType}>Add MEP Type</button>
                </div>
                <table className="purchase-type-table">
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Purchase Type</th>
                            <th>Notes</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mepTypes.map((type) => (
                            <tr key={type.SN}>
                                <td>{type.SN}</td>
                                <td>{type.Type_Name}</td>
                                <td>{type.Notes}</td>
                                <td><button onClick={() => handleDeleteMepType(type.SN)}>Delete</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h2>SCP Purchase Type Setup</h2>
                <div className="purchase-type-form">
                    <input
                        type="text"
                        placeholder="Purchase Type"
                        value={newScpType.typeName}
                        onChange={(e) => setNewScpType({ ...newScpType, typeName: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Notes"
                        value={newScpType.notes}
                        onChange={(e) => setNewScpType({ ...newScpType, notes: e.target.value })}
                    />
                    <button onClick={handleAddScpType}>Add SCP Type</button>
                </div>
                <table className="purchase-type-table">
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Purchase Type</th>
                            <th>Notes</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scpTypes.map((type) => (
                            <tr key={type.SN}>
                                <td>{type.SN}</td>
                                <td>{type.Type_Name}</td>
                                <td>{type.Notes}</td>
                                <td><button onClick={() => handleDeleteScpType(type.SN)}>Delete</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default PurchaseTypeManager;
