import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './staffView.css';
import Header from '../../../misc/header';

const StaffView = () => {
  const { staffNumber, staffMi, befr } = useParams();

  const [staffData, setStaffData] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState({
    yesNo: ["Yes", "No"],
    securityClearance: ["NA", "SECRET", "RELIABILITY STATUS"],
  });

  useEffect(() => {
    axios.get(`http://localhost:3000/api/employees/view/${staffNumber}`)
      .then(response => {
        setStaffData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the employee data!', error);
      });
  }, [staffNumber]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStaffData({ ...staffData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`/hr/employees/view/${staffNumber}`, staffData)
      .then(response => {
        alert('Staff details updated successfully!');
      })
      .catch(error => {
        console.error('There was an error updating the employee data!', error);
      });
  };

  const formatDate = (dateString) => {
    return dateString && dateString !== "0000-00-00" ? dateString.split('T')[0] : '';
  };

  return (
    <>
    <Header />
        <form className="staff-form" onSubmit={handleSubmit}>
        <div className="form-section">
            <h2>Personal Information</h2>
            <label>
            Company Code:
            <input type="text" value={staffData.company_code} disabled />
            </label>
            <label>
            Staff Name:
            <input type="text" name="Staff_Name" value={staffData.Staff_Name || ''} onChange={handleInputChange} />
            </label>
            <label>
            Staff Password:
            <input type="text" name="Staff_Password" value={staffData.Staff_PassWord || ''} onChange={handleInputChange} />
            </label>
            <label>
            SIN Number:
            <input type="text" name="SIN_Number" value={staffData.SIN_Number || ''} onChange={handleInputChange} />
            </label>
            <label>
            Migrant Status:
            <input type="text" name="Migrant_Status" value={staffData.Migrant_Status || ''} onChange={handleInputChange} />
            </label>
            <label>
            Security Clearance:
            <select name="Security_Clearance" value={staffData.Security_Clearance || ''} onChange={handleInputChange}>
                {dropdownOptions.securityClearance.map(option => (
                <option key={option} value={option}>{option}</option>
                ))}
            </select>
            </label>
            <label>
            Staff Birthday:
            <input type="date" name="Staff_Birthday" value={formatDate(staffData.Staff_Birthday)} onChange={handleInputChange} />
            </label>
            <label>
            E Name:
            <input type="text" name="E_Name" value={staffData.E_Name || ''} onChange={handleInputChange} />
            </label>
            <label>
            E Cell Phone:
            <input type="text" name="E_Cell_Phone" value={staffData.E_Cell_Phone || ''} onChange={handleInputChange} />
            </label>
            <label>
            Home Address:
            <input type="text" name="Home_Address" value={staffData.Home_Address || ''} onChange={handleInputChange} />
            </label>
            <label>
            E Relationship:
            <input type="text" name="E_Relationship" value={staffData.E_Relationship || ''} onChange={handleInputChange} />
            </label>
            <label>
            Working Email:
            <input type="email" name="Staff_Working_Email" value={staffData.Staff_Working_Email || ''} onChange={handleInputChange} />
            </label>
            <label>
            Private Email:
            <input type="email" name="Staff_Private_Email" value={staffData.Staff_Private_Email || ''} onChange={handleInputChange} />
            </label>
            <label>
            Phone Number:
            <input type="text" name="Staff_PhoneNumber" value={staffData.Staff_PhoneNumber || ''} onChange={handleInputChange} />
            </label>
        </div>

        <div className="form-section">
            <h2>Job Information</h2>
            <label>
            Job Title:
            <input type="text" name="Job_Title" value={staffData.Job_Title || ''} onChange={handleInputChange} />
            </label>
            <label>
            Department:
            <input type="text" name="Department" value={staffData.Department || ''} onChange={handleInputChange} />
            </label>
            <label>
            Starting Date:
            <input type="date" name="Starting_date" value={formatDate(staffData.Starting_date)} onChange={handleInputChange} />
            </label>
            <label>
            Annual Salary Start Date:
            <input type="date" name="Annual_salary_start_date" value={formatDate(staffData.Annual_salary_start_date)} onChange={handleInputChange} />
            </label>
            <label>
            Resignation Date:
            <input type="date" name="Resignation_date" value={formatDate(staffData.Resignation_date)} onChange={handleInputChange} />
            </label>
            <label>
            ROE Issued Date:
            <input type="date" name="ROE_issued_date" value={formatDate(staffData.ROE_issued_date)} onChange={handleInputChange} />
            </label>
            <label>
            Online or Leave:
            <input type="text" name="Online_or_Leave" value={staffData.Online_or_Leave || ''} onChange={handleInputChange} />
            </label>
            <label>
            WH Record Needed:
            <select name="WH_record_needed" value={staffData.WH_record_needed || ''} onChange={handleInputChange}>
                {dropdownOptions.yesNo.map(option => (
                <option key={option} value={option}>{option}</option>
                ))}
            </select>
            </label>
        </div>

        <div className="form-section">
            <h2>Skills and Positions</h2>
            {Object.keys(staffData).filter(key => key.startsWith('Position_')).map(key => (
            <label key={key}>
                {key.replace('Position_', '').replace(/_/g, ' ')}:
                <select name={key} value={staffData[key] || ''} onChange={handleInputChange}>
                {dropdownOptions.yesNo.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
                </select>
            </label>
            ))}
        </div>

        <div className="form-section">
            <h2>Miscellaneous Information</h2>
                <label>
                Group Email Sent:
                <select name="Group_email_sent" value={staffData.Group_email_sent || ''} onChange={handleInputChange}>
                    {dropdownOptions.yesNo.map(option => (
                    <option key={option} value={option}>{option}</option>
                    ))}
                </select>
                </label>
                <label>
                Service Fee Rate:
                <input type="number" name="Service_Fee_Rate" value={staffData.Service_Fee_Rate || ''} onChange={handleInputChange} />
                </label>
                <label>
                Vacation Hours Year Rate:
                <input type="text" name="Vacation_Hours_Year_Rate" value={staffData.Vacation_Hours_Year_Rate || ''} onChange={handleInputChange} />
                </label>
                <label>
                Sick Off Hours Rate:
                <input type="text" name="Sick_Off_Hours_Rate" value={staffData.Sick_Off_Hours_Rate || ''} onChange={handleInputChange} />
                </label>
                <label>
                Full or Part Time:
                <input type="text" name="Full_or_Part_Time" value={staffData.Full_or_Part_Time || ''} onChange={handleInputChange} />
                </label>
                <label>
                Working PC:
                <select name="Working_PC" value={staffData.Working_PC || ''} onChange={handleInputChange}>
                    {dropdownOptions.yesNo.map(option => (
                    <option key={option} value={option}>{option}</option>
                    ))}
                </select>
                </label>
                <label>
                Benefits Start Date:
                <input type="date" name="Benefits_start_date" value={formatDate(staffData.Benefits_start_date)} onChange={handleInputChange} />
                </label>
                <label>
                Re-hire:
                <select name="Re_hire" value={staffData.Re_hire || ''} onChange={handleInputChange}>
                    {dropdownOptions.yesNo.map(option => (
                    <option key={option} value={option}>{option}</option>
                    ))}
                </select>
                </label>
                <label>
                Reason for Leaving:
                <textarea name="Reason_for_leaving" value={staffData.Reason_for_leaving || ''} onChange={handleInputChange}></textarea>
                </label>
                <label>
                Gender:
                <input type="text" name="Gender" value={staffData.Gender || ''} onChange={handleInputChange} />
                </label>
                <label>
                    OT Pay:
                    <input type="number" name="OT_Pay" value={staffData.OT_Pay || ''} onChange={handleInputChange} />
                </label>
                <label>
                    Working Office:
                    <input type="text" name="working_office" value={staffData.working_office || ''} onChange={handleInputChange} />
                </label>
                <label>
                    Supervisor Name:
                    <input type="text" name="Supervisor_Name" value={staffData.Supervisor_Name || ''} onChange={handleInputChange} />
                </label>
                <label>
                    P KPI:
                    <input type="number" name="P_KPI" value={staffData.P_KPI || ''} onChange={handleInputChange} />
                </label>
                        
                <button type="submit">Save</button>
            </div>
            
        </form>
    </>
    
  );
};

export default StaffView;
