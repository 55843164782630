import Header from '../../misc/header.js';
import React from 'react';
import './settingsProject.css';

const SettingsProject = () => {
    return (
        <div>
            <Header />
            <div className="container">
                <div className="left-buttons">
                    <a href="/projects/settings/positionList" className="button">Team Members</a>
                    <a href="/projects/settings/budget-allocation" className="button">Budget Allocation</a>
                </div>
                <div className='middle-buttons'>
                    <a href="/projects/settings/scp" className="button">Subconsultant Service Orders</a>
                    <a href="/projects/settings/mep" className="button">Materials or Equipment Purchase Orders</a>
                    <a href="/projects/settings/co" className="button">Change Orders</a>
                    <a href="/projects/settings/services" className="button">Project Services and Milestones</a>
                </div>
                <div className='right-buttons'> 
                <a href="/projects/settings/delete" className="button delete-button">Delete Project</a>
                </div>
            </div>
         </div>
    );
};

export default SettingsProject;