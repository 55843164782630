import Header from '../../misc/header';
import './mepVendorView.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './mepVendorCreate.css';

function VendorCreate() {
    const navigate = useNavigate();
    const [vendor, setVendor] = useState({
        number: '',
        name: '',
        owner: '',
        type: '',
        payment_type: '',
        province: '',
        city: '',
        postal_code: '',
        address: '',
        tel_1: '',
        tel_2: '',
        tel_d: '',
        email: '',
        web: '',
        notes: '',
        bad_flag: ''
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVendor(prevVendor => ({
            ...prevVendor,
            [name]: value
        }));
    };

    const mapVendorToApiFormat = (vendor) => {
        return {
            SC_number: vendor.number,
            SC_name: vendor.name,
            SC_Owner: vendor.owner,
            SC_type: vendor.type,
            SC_payment_type: vendor.payment_type,
            SC_Province: vendor.province,
            SC_City: vendor.city,
            SC_Postal_Code: vendor.postal_code,
            SC_Address: vendor.address,
            SC_Tel_1: vendor.tel_1,
            SC_Tel_2: vendor.tel_2,
            SC_Tel_D: vendor.tel_d,
            SC_Email: vendor.email,
            SC_Web: vendor.web,
            SC_Notes: vendor.notes,
            SC_Bad_flag: vendor.bad_flag
        };
    };

    const handleCreate = async () => {
        if (!vendor.number || vendor.number.trim() === '') {
            alert('Vendor Number cannot be empty');
            return;
        }
    
        setLoading(true);
        try {
            const vendorData = mapVendorToApiFormat(vendor);
            await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/addVendor', vendorData);
            navigate(`/vendors/MEP/${vendorData.SC_number}`);
        } catch (error) {
            console.error('Error creating vendor:', error);
        } finally {
            setLoading(false);
        }
    };

    
    return (
        <>
            <Header />
            <div className="vendorViewContainer">
                {loading ? (
                    <div className='Loading'>Loading...</div>
                ) : (
                    <div className="vendorDetail">
                        <div className="vendorDetailTop">
                            <h1 className="vendorNumber">Create New Vendor</h1>
                            <button onClick={handleCreate} className="MEPcreateButton">Create</button>
                        </div>
                        <form className="vendorForm">
                            <div className="formGroup">
                                <label>Vendor Number:</label>
                                <input type="text" name="number" value={vendor.SC_number} onChange={handleChange} />
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Vendor Name:</label>
                                    <input type="text" name="name" value={vendor.SC_name} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Owner:</label>
                                    <input type="text" name="owner" value={vendor.owner} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Type:</label>
                                    <input type="text" name="type" value={vendor.type} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Address:</label>
                                    <input type="text" name="address" value={vendor.address} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Postal Code:</label>
                                    <input type="text" name="postal_code" value={vendor.postal_code} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>City:</label>
                                    <input type="text" name="city" value={vendor.city} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Province:</label>
                                    <input type="text" name="province" value={vendor.province} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Payment Type:</label>
                                    <input type="text" name="payment_type" value={vendor.payment_type} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Tel 1:</label>
                                    <input type="text" name="tel_1" value={vendor.tel_1} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Tel 2:</label>
                                    <input type="text" name="tel_2" value={vendor.tel_2} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Direct Phone:</label>
                                    <input type="text" name="tel_d" value={vendor.tel_d} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Email:</label>
                                    <input type="text" name="email" value={vendor.email} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Web:</label>
                                    <input type="text" name="web" value={vendor.web} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Notes:</label>
                                    <textarea name="notes" value={vendor.notes} onChange={handleChange}></textarea>
                                </div>
                            </div>
                            <div className="formRow formRowBottom">
                                <div className="formGroup">
                                    <label>Bad Flag:</label>
                                    <select name="bad_flag" value={vendor.bad_flag} onChange={handleChange}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
}

export default VendorCreate;