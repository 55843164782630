import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './positionList.css';
import Header from '../../misc/header';

function PositionList() {
    const [projectNumber, setProjectNumber] = useState('');
    const [teamMembers, setTeamMembers] = useState([]);
    const [newMember, setNewMember] = useState({
        discipline: '',
        position: '',
        staffName: '',
        note: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [projectExists, setProjectExists] = useState(false);

    useEffect(() => {
        if (submitted) {
            axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/positionList/${projectNumber}`)
                .then(response => {
                    setTeamMembers(response.data);
                    setProjectExists(true);
                })
                .catch(error => {
                    console.error('Failed to fetch team members:', error);
                    alert('Project not found. Please check the project number and try again.');
                    setProjectExists(false);
                    setSubmitted(false);
                });
        }
    }, [projectNumber, submitted]);

    const handleDelete = (positionAssignNumber) => {
        axios.delete(`https://ziyutecerp-9re9z.ondigitalocean.app/api/deleteTeamMember/${positionAssignNumber}`)
            .then(response => {
                setTeamMembers(teamMembers.filter(member => member.Position_Assign_Number !== positionAssignNumber));
            })
            .catch(error => {
                console.error('Failed to delete team member:', error);
            });
    };

    const handleNewMemberChange = (e) => {
        const { name, value } = e.target;
        setNewMember(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleNewMemberSubmit = () => {
        const memberData = { ...newMember, projectNumber };
        axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/addTeamMember', memberData)
            .then(response => {
                setTeamMembers([...teamMembers, response.data]);
                setNewMember({ discipline: '', position: '', staffName: '', note: '' });
            })
            .catch(error => {
                console.error('Failed to add new team member:', error);
            });
    };

    const handleProjectNumberSubmit = () => {
        setSubmitted(true);
    };

    const groupedByDiscipline = teamMembers.reduce((acc, member) => {
        if (!acc[member.Discipline]) acc[member.Discipline] = [];
        acc[member.Discipline].push(member);
        return acc;
    }, {});

    return (
        <>
            <Header />
            <div className="position-list-container">
                <h2>Project Team Members</h2>
                {!submitted || !projectExists ? (
                    <div className="form-row">
                        <label>Project Number:</label>
                        <input
                            type="text"
                            value={projectNumber}
                            onChange={(e) => setProjectNumber(e.target.value)}
                            className="short-input"
                            />
                            <button onClick={handleProjectNumberSubmit}>Submit</button>
                        </div>
                    ) : (
                        <>
                            {Object.entries(groupedByDiscipline).map(([discipline, members], index) => (
                                <div key={index} className="discipline-group">
                                    <h3>{discipline}</h3>
                                    <table className="team-members-table">
                                        <thead>
                                            <tr>
                                                <th>Position</th>
                                                <th>Staff Name</th>
                                                <th>Notes</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {members.map(member => (
                                                <tr key={member.Position_Assign_Number}>
                                                    <td>{member.Position}</td>
                                                    <td>{member.Staff_Name}</td>
                                                    <td>{member.Note}</td>
                                                    <td>
                                                        <button onClick={() => handleDelete(member.Position_Assign_Number)}>Delete</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
    
                            <h3 className="new-member-title">Add New Team Member</h3>
                            <div className="new-member-form">
                                <div className="form-row">
                                    <label>Discipline:</label>
                                    <input
                                        type="text"
                                        name="discipline"
                                        value={newMember.discipline}
                                        onChange={handleNewMemberChange}
                                        className="short-input"
                                    />
                                </div>
                                <div className="form-row">
                                    <label>Position:</label>
                                    <input
                                        type="text"
                                        name="position"
                                        value={newMember.position}
                                        onChange={handleNewMemberChange}
                                        className="short-input"
                                    />
                                </div>
                                <div className="form-row">
                                    <label>Staff Name:</label>
                                    <input
                                        type="text"
                                        name="staffName"
                                        value={newMember.staffName}
                                        onChange={handleNewMemberChange}
                                        className="short-input"
                                    />
                                </div>
                                <div className="form-row">
                                    <label>Notes:</label>
                                    <textarea
                                        name="note"
                                        value={newMember.note}
                                        onChange={handleNewMemberChange}
                                        className="note-input"
                                    />
                                </div>
                                <div className="form-row">
                                    <button onClick={handleNewMemberSubmit}>Add Team Member</button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }
export default PositionList;