import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../../misc/header';
import './searchProposal.css';
import ProposalSearchForm from '../../misc/searchformProposal';

function ProposalSearch() {
    const { keyword } = useParams();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);

    const [sortField, setSortField] = useState('Proposal_Number');
    const [sortDirection, setSortDirection] = useState('asc');

    const [filterField, setFilterField] = useState(null);
    const [filterValue, setFilterValue] = useState(null);

    const filteredAndSortedResults = [...results]
    .filter(result => filterField === null || filterValue === '' || result[filterField] === filterValue)
    .sort((a, b) => {
      const aValue = a[sortField] !== undefined && a[sortField] !== null ? a[sortField] : 0;
      const bValue = b[sortField] !== undefined && b[sortField] !== null ? b[sortField] : 0;
  
      const aStr = typeof aValue === 'string' ? aValue : String(aValue);
      const bStr = typeof bValue === 'string' ? bValue : String(bValue);
  
      const aNum = parseFloat(aStr.replace(/,/g, ''));
      const bNum = parseFloat(bStr.replace(/,/g, ''));
  
      if (!isNaN(aNum) && !isNaN(bNum)) {
        return sortDirection === 'asc' ? aNum - bNum : bNum - aNum;
      } else {
        if (aStr < bStr) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (aStr > bStr) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      }
    });

    
    useEffect(() => {
        if (keyword) {
          setLoading(true);
          const fetchData = async () => {
              try {
                  const response = await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/proposalSearch', { KeyWordSearch: keyword });
                  setResults(response.data);
              } catch (error) {
                  console.error('An error occurred:', error);
              } finally {
                  setLoading(false);
              }
          };
  
          fetchData();
        }
      }, [keyword]);
    

      return (
        <>
            <Header />
            <ProposalSearchForm />
            {keyword && (
            <>
            {loading ? (
                <div className='Loading'>Loading...</div>
            ) : (
                <div>
                    <h1 className="searchResultsTitle">PROPOSAL SEARCH RESULTS FOR "{keyword}"</h1>
                    {Array.isArray(results) && (
                        <table className="myTable">
                            <thead>
                                <tr>
                                    <td className="boldText" width='10%' bgcolor='#86C4E7' onClick={() => {
                                        setSortField('Proposal_Number');
                                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                    }}>
                                        <button className="yipButtonStyle sortButton">
                                            <span style={{display: 'inline-block', width: '100%'}}>Proposal Number</span> 
                                            <span className='sortButtonSpan'> {sortField === 'Proposal_Number' ? (sortDirection === 'asc' ? '▲' : '▼') : '-'}</span>
                                        </button>
                                    </td>
                                    <td className="boldText" width='40%' bgcolor='#E0EFF8'>
                                        <button className="yipButtonStyle sortButton" onClick={() => {
                                            setSortField('Proposal_Name');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}>
                                                <span style={{display: 'inline-block', width: '100%'}}>Proposal Name</span>
                                        <span className='sortButtonSpan'> {sortField === 'Proposal_Name' ? (sortDirection === 'asc' ? '▲' : '▼') : '-'}</span>
                                    </button>
                                    </td>
                                    <td className="boldText" width='10%' align='center' bgcolor='#86C4E7'>
                                        <select className="customSelect" onChange={e => {
                                            setFilterField('City');
                                            setFilterValue(e.target.value);
                                        }}>
                                            <option value="">All Cities</option>
                                            {[...new Set(results.map(result => result.City).filter(city => city))].map(city => (
                                            <option key={city} value={city}>{city}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td className="boldText" width='10%' align='center' bgcolor='#E0EFF8'>
                                        <select className="customSelect" onChange={e => {
                                            setFilterField('Proposal_Type');
                                            setFilterValue(e.target.value);
                                        }}>
                                            <option value="">All Proposal Types</option>
                                            {[...new Set(results.map(result => result.Proposal_Type).filter(type => type))].map(type => (
                                            <option key={type} value={type}>{type}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td className="boldText" width='10%' align='center' bgcolor='#86C4E7'>
                                        <button className="yipButtonStyle sortButton" onClick={() => {
                                            setSortField('Bid_Price');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}>
                                            <span style={{display: 'inline-block', width: '100%'}}>Bid Price</span>
                                            <span className='sortButtonSpan'>{sortField === 'Bid_Price' ? (sortDirection === 'asc' ? '▲' : '▼') : '-'}</span>
                                        </button>
                                    </td>
                                    <td className="boldText" width='10%' align='center' bgcolor='#E0EFF8'>
                                        <button className="yipButtonStyle sortButton" onClick={() => {
                                            setSortField('Awarded_Price');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}>
                                            <span style={{display: 'inline-block', width: '100%'}}>Awarded Price</span>
                                            <span className='sortButtonSpan'> {sortField === 'Awarded_Price' ? (sortDirection === 'asc' ? '▲' : '▼') : '-'} </span>
                                        </button>
                                    </td>
                                    <td className="boldText" width='10%' align='center' bgcolor='#86C4E7'>
                                        <select className="customSelect" onChange={e => {
                                            setFilterField('Award_or_Fail');
                                            setFilterValue(e.target.value);
                                        }}>
                                            <option value="">All Statuses</option>
                                            {[...new Set(results.map(result => result.Award_or_Fail).filter(status => status))].map(status => (
                                            <option key={status} value={status}>{status}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredAndSortedResults.map(result => (
                                    <tr key={result.id}> 
                                        <td>
                                            <a href={`/proposals/view/${result.Proposal_Number}`} className='searchVPLink'>
                                                {result.Proposal_Number}
                                            </a>
                                        </td>
                                        <td>{result.Proposal_Name}</td>
                                        <td>{result.City}</td>
                                        <td>{result.Proposal_Type}</td>
                                        <td className="alignRight">{result.Bid_Price}</td>
                                        <td className="alignRight">{result.Awarded_Price}</td>
                                        <td>{result.Award_or_Fail}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                            )}
                            </div>
                        )}
                    </>
                )}
            </>
        );
    }
export default ProposalSearch;
