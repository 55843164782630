import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../../misc/header';
import './contactList.css';

function ContactList() {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        fetchContacts();
    }, []);

    const fetchContacts = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://ziyutecerp-9re9z.ondigitalocean.app/api/contacts', {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            });
            const validContacts = response.data.filter(contact => contact.C_C_Number);
            setContacts(validContacts);
        } catch (error) {
            console.error('Error fetching contacts:', error);
        } finally {
            setLoading(false);
        }
    };

    const filteredContacts = keyword
        ? contacts.filter(contact =>
            (contact.C_C_Name && contact.C_C_Name.toLowerCase().includes(keyword.toLowerCase())) ||
            (contact.C_C_Email_W && contact.C_C_Email_W.toLowerCase().includes(keyword.toLowerCase())) ||
            (contact.C_C_Number && contact.C_C_Number.toString().includes(keyword)) ||
            (contact.C_C_Phone_1 && contact.C_C_Phone_1.toLowerCase().includes(keyword.toLowerCase()))
        )
        : contacts;

    return (
        <>
            <Header />
            <div className="ContactSearchBarContainer">
                <div className="ContactSearchBar">
                    <input
                        type="text"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder="Search Contacts"
                    />
                </div>
                <div className="ContactTitleContainer">
                    <div className='ContactTitle'>CONTACT PERSON LIST</div>
                </div>
                <a href="/contacts/new" className="createContactButton">
                    Create New Contact
                </a>
            </div>

            {loading ? (
                <div className='Loading'>Loading...</div>
            ) : (
                <div>
                    <table className="contactTable">
                        <thead>
                            <tr>
                                <th>Contact Person Number</th>
                                <th>Name</th>
                                <th>Telephone 1</th>
                                <th>Telephone 2</th>
                                <th>Email (Work)</th>
                                <th>Email (Personal)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredContacts.length > 0 ? (
                                filteredContacts.map(contact => (
                                    <tr key={contact.C_C_Number}>
                                        <td>
                                            <Link to={`/contacts/${contact.C_C_Number}`} className="contactLink">
                                                {contact.C_C_Number}
                                            </Link>
                                        </td>
                                        <td>{contact.C_C_Name}</td>
                                        <td>{contact.C_C_Phone_1}</td>
                                        <td>{contact.C_C_Phone_2}</td>
                                        <td>{contact.C_C_Email_W}</td>
                                        <td>{contact.C_C_Email_P}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="noResults">No results found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default ContactList;