import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './staffList.css';

const StaffList = () => {
    const [staffList, setStaffList] = useState([]);

    useEffect(() => {
        fetchStaffList();
    }, []);

    const fetchStaffList = async () => {
        try {
            const response = await axios.get('http://localhost:3000/api/staffList');
            setStaffList(response.data);
        } catch (error) {
            console.error('Error fetching staff list:', error);
        }
    };

    const removeStaff = async (Staff_Number) => {
        try {
            await axios.delete(`/api/removeStaff/${Staff_Number}`);
            fetchStaffList(); // Refresh the list after removing
        } catch (error) {
            console.error('Error removing staff member:', error);
        }
    };

    return (
        <div className="staff-list-container">
            <h4>Complete Employee Record List</h4>
            <table className="staff-list-table">
                <thead>
                    <tr>
                        <th>Employee Number</th>
                        <th>Name</th>
                        <th>Working Email</th>
                        <th>Cell Phone Number</th>
                        <th>Status</th>
                        <th>Job Title</th>
                        <th>Department</th>
                        <th>Supervisor</th>
                        <th>Office Location</th>
                        <th>Security Clearance</th>
                        <th>Birthday</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {staffList.map(staff => (
                        <tr key={staff.Staff_Number}>
                            <td align="center">
                                <a href={`/hr/employees/view/${staff.Staff_Number}`}>
                                    {staff.Staff_Number}
                                </a>
                            </td>
                            <td>{staff.Staff_Name}</td>
                            <td>{staff.Staff_Working_Email}</td>
                            <td>{staff.Staff_PhoneNumber}</td>
                            <td className={staff.Online_or_Leave === 'Leave' ? 'status-leave' : 
                                           staff.Online_or_Leave === 'INTERSHIP' ? 'status-internship' : 'status-current'}>
                                {staff.Online_or_Leave === 'Leave' ? 'Former Employee' : 
                                 staff.Online_or_Leave === 'INTERSHIP' ? 'INTERNSHIP' : 'Current Employee'}
                            </td>
                            <td>{staff.Job_Title}</td>
                            <td>{staff.Department}</td>
                            <td>{staff.Supervisor_Name}</td>
                            <td>{staff.working_office}</td>
                            <td>{staff.Security_Clearance}</td>
                            <td>{staff.Staff_Birthday}</td>
                            <td>
                                <button className="remove-btn" onClick={() => removeStaff(staff.Staff_Number)}>Remove</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StaffList;
