import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './mepPurchaseList.css';
import Header from '../../misc/header';

function MEPPurchaseOrderList() {
    const [projectNumber, setProjectNumber] = useState('');
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
    const [items, setItems] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    // Fetch purchase orders when project number is submitted
    useEffect(() => {
        if (submitted && projectNumber) {
            axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/mepPurchaseOrders/${projectNumber}`)
                .then(response => {
                    setPurchaseOrders(response.data);
                    setSelectedPurchaseOrder(null);
                    setItems([]);
                })
                .catch(error => {
                    console.error('Error fetching purchase orders:', error);
                    setSubmitted(false);
                });
        }
    }, [submitted, projectNumber]);

    // Fetch purchase order details when a PO is selected
    useEffect(() => {
        if (selectedPurchaseOrder) {
            axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/mepPurchaseOrder/${selectedPurchaseOrder}`)
                .then(response => {
                    setItems(response.data.items);
                })
                .catch(error => {
                    console.error('Error fetching purchase order details:', error);
                });
        }
    }, [selectedPurchaseOrder]);

    const handleProjectNumberSubmit = () => {
        setSubmitted(true);
    };

    const handleItemChange = (e, index, field) => {
        const value = e.target.value;
        const updatedItems = [...items];
        updatedItems[index][field] = value;
        setItems(updatedItems);
    };

    const handleItemUpdate = (index) => {
        const item = items[index];
        axios.post(`https://ziyutecerp-9re9z.ondigitalocean.app/api/updateMEPItem`, { purchaseOrderNo: selectedPurchaseOrder, item })
            .then(response => {
                alert('Item updated successfully');
            })
            .catch(error => {
                console.error('Error updating item:', error);
            });
    };

    const handleItemDelete = (index) => {
        const item = items[index];
        axios.post(`https://ziyutecerp-9re9z.ondigitalocean.app/api/deleteMEPItem`, { purchaseOrderNo: selectedPurchaseOrder, item })
            .then(response => {
                setItems(items.filter((_, i) => i !== index));
                alert('Item deleted successfully');
            })
            .catch(error => {
                console.error('Error deleting item:', error);
            });
    };

    const handleMainUpdate = () => {
        axios.post(`https://ziyutecerp-9re9z.ondigitalocean.app/api/updateMEPPurchaseOrder`, { purchaseOrderNo: selectedPurchaseOrder, purchaseOrder: selectedPurchaseOrder })
            .then(response => {
                alert('Purchase order updated successfully');
            })
            .catch(error => {
                console.error('Error updating purchase order:', error);
            });
    };

    const handleDeleteRecord = () => {
        axios.post(`https://ziyutecerp-9re9z.ondigitalocean.app/api/deleteMEPPurchaseOrder`, { purchaseOrderNo: selectedPurchaseOrder })
            .then(response => {
                alert('Purchase order deleted successfully');
                setSubmitted(false);
                setSelectedPurchaseOrder(null);
            })
            .catch(error => {
                console.error('Error deleting purchase order:', error);
            });
    };

    return (
        <>
            <Header />
            <div className="mep-purchase-order-container">
                <h2>MEP Purchase Order List</h2>

                {!submitted ? (
                    <div className="form-row">
                        <label>Project Number:</label>
                        <input
                            type="text"
                            value={projectNumber}
                            onChange={(e) => setProjectNumber(e.target.value)}
                            className="short-input"
                        />
                        <button onClick={handleProjectNumberSubmit}>Submit</button>
                    </div>
                ) : (
                    <>
                        {!selectedPurchaseOrder ? (
                            <div>
                                <h3>Purchase Orders for Project {projectNumber}</h3>
                                <table className="purchase-order-table">
                                    <thead>
                                        <tr>
                                            <th>PO Number</th>
                                            <th>Project Number</th>
                                            <th>Vendor Name</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {purchaseOrders.map((order, index) => (
                                            <tr key={index}>
                                                <td>{order.Purchase_Order_No}</td>
                                                <td>{order.Project_Number}</td>
                                                <td>{order.SC_Name}</td>
                                                <td>
                                                    {isNaN(parseFloat(order.Amount)) ? 'Invalid Amount' : `$${parseFloat(order.Amount).toFixed(2)}`}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <>
                                <div className="purchase-order-detail">
                                    <h3>Details for PO: {selectedPurchaseOrder}</h3>
                                    <div className="form-row">
                                        <label>Project Number:</label>
                                        <input
                                            type="text"
                                            value={purchaseOrders.find(po => po.Purchase_Order_No === selectedPurchaseOrder).Project_Number}
                                            onChange={(e) => setSelectedPurchaseOrder({ ...selectedPurchaseOrder, Project_Number: e.target.value })}
                                            className="short-input"
                                        />
                                    </div>
                                    <div className="form-row">
                                        <label>MEP Vendor Name:</label>
                                        <input
                                            type="text"
                                            value={purchaseOrders.find(po => po.Purchase_Order_No === selectedPurchaseOrder).SC_Name}
                                            onChange={(e) => setSelectedPurchaseOrder({ ...selectedPurchaseOrder, SC_Name: e.target.value })}
                                            className="short-input"
                                        />
                                    </div>
                                    <div className="form-row">
                                        <label>Payment Type:</label>
                                        <select
                                            value={purchaseOrders.find(po => po.Purchase_Order_No === selectedPurchaseOrder).Payment_types}
                                            onChange={(e) => setSelectedPurchaseOrder({ ...selectedPurchaseOrder, Payment_types: e.target.value })}
                                            className="short-input"
                                        >
                                            <option value="Cash">Cash</option>
                                            <option value="Credit Card">Credit Card</option>
                                            <option value="Cheque">Cheque</option>
                                            <option value="Bank transfer - E-transfer">Bank transfer - E-transfer</option>
                                            <option value="Bank transfer - ETF">Bank transfer - ETF</option>
                                        </select>
                                    </div>
                                    <div className="form-row">
                                        <label>Contract Type:</label>
                                        <select
                                            value={purchaseOrders.find(po => po.Purchase_Order_No === selectedPurchaseOrder).Contract_types}
                                            onChange={(e) => setSelectedPurchaseOrder({ ...selectedPurchaseOrder, Contract_types: e.target.value })}
                                            className="short-input"
                                        >
                                            <option value="Hourly Rate">Hourly Rate</option>
                                            <option value="Lump Sum">Lump Sum</option>
                                            <option value="Lump Sum + Hourly Rate">Lump Sum + Hourly Rate</option>
                                        </select>
                                    </div>
                                    <div className="form-row">
                                        <label>Note:</label>
                                        <textarea
                                            value={purchaseOrders.find(po => po.Purchase_Order_No === selectedPurchaseOrder).Note}
                                            onChange={(e) => setSelectedPurchaseOrder({ ...selectedPurchaseOrder, Note: e.target.value })}
                                            className="note-input"
                                        />
                                    </div>
                                    <button onClick={handleMainUpdate}>Update Main Record</button>
                                    <button onClick={handleDeleteRecord} style={{ backgroundColor: '#A166C4', color: 'white' }}>Delete Purchase Order</button>

                                    <h3>Items</h3>
                                    {items.map((item, index) => (
                                        <div key={index} className="item-row">
                                            <div className="form-row">
                                                <label>Item Name:</label>
                                                <input
                                                    type="text"
                                                    value={item.itemName}
                                                    onChange={(e) => handleItemChange(e, index, 'itemName')}
                                                    className="short-input"
                                                />
                                            </div>
                                            <div className="form-row">
                                                <label>Item Type:</label>
                                                <input
                                                    type="text"
                                                    value={item.itemType}
                                                    onChange={(e) => handleItemChange(e, index, 'itemType')}
                                                    className="short-input"
                                                />
                                            </div>
                                            <div className="form-row">
                                                <label>Specifications:</label>
                                                <input
                                                    type="text"
                                                    value={item.SPC}
                                                    onChange={(e) => handleItemChange(e, index, 'SPC')}
                                                    className="short-input"
                                                />
                                            </div>
                                            <div className="form-row">
                                                <label>Unit Price:</label>
                                                <input
                                                    type="number"
                                                    value={item.unitPrice}
                                                    onChange={(e) => handleItemChange(e, index, 'unitPrice')}
                                                    className="short-input"
                                                />
                                            </div>
                                            <div className="form-row">
                                                <label>Quantity:</label>
                                                <input
                                                    type="number"
                                                    value={item.qty}
                                                    onChange={(e) => handleItemChange(e, index, 'qty')}
                                                    className="short-input"
                                                />
                                            </div>
                                            <div className="form-row">
                                                <label>Note:</label>
                                                <input
                                                    type="text"
                                                    value={item.note}
                                                    onChange={(e) => handleItemChange(e, index, 'note')}
                                                    className="short-input"
                                                />
                                            </div>
                                            <button onClick={() => handleItemUpdate(index)} style={{ backgroundColor: '#F1DE5D', color: 'black' }}>Modify</button>
                                            <button onClick={() => handleItemDelete(index)} style={{ backgroundColor: '#A166C4', color: 'white' }}>Delete</button>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default MEPPurchaseOrderList;
