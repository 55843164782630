import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './clientSettings.css';
import Header from '../../misc/header';

function ClientTypeManager() {
    const [clientTypes, setClientTypes] = useState([]);
    const [newClientType, setNewClientType] = useState({ typeName: '', notes: '' });

    useEffect(() => {
        fetchClientTypes();
    }, []);

    const fetchClientTypes = async () => {
        try {
            const response = await axios.get('https://ziyutecerp-9re9z.ondigitalocean.app/api/client-types');
            setClientTypes(response.data);
        } catch (error) {
            console.error('Failed to fetch client types', error);
        }
    };

    const handleAddClientType = async () => {
        try {
            await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/client-types', newClientType);
            setNewClientType({ typeName: '', notes: '' });
            fetchClientTypes();
        } catch (error) {
            console.error('Failed to add client type', error);
        }
    };

    const handleDeleteClientType = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this client type?');
        if (confirmDelete) {
            try {
                await axios.delete(`https://ziyutecerp-9re9z.ondigitalocean.app/api/client-types/${id}`);
                fetchClientTypes();
            } catch (error) {
                console.error('Failed to delete client type', error);
            }
        }
    };

    return (
        <>
            <Header />
            <div className="client-type-manager">
                <h2>Client Type Setup</h2>
                <div className="client-type-form">
                    <input
                        type="text"
                        placeholder="Client Type"
                        value={newClientType.typeName}
                        onChange={(e) => setNewClientType({ ...newClientType, typeName: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Notes"
                        value={newClientType.notes}
                        onChange={(e) => setNewClientType({ ...newClientType, notes: e.target.value })}
                    />
                    <button onClick={handleAddClientType}>Add New</button>
                </div>
                <table className="client-type-table">
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Client Type</th>
                            <th>Notes</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientTypes.map((type) => (
                            <tr key={type.SN}>
                                <td>{type.SN}</td>
                                <td>{type.C_Client_Type}</td>
                                <td>{type.Notes}</td>
                                <td><button onClick={() => handleDeleteClientType(type.SN)}>Delete</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ClientTypeManager;
