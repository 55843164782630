import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Homepage from './pages/homepage/home.js';
import AboutPage from './pages/about/about.js';
import NotFoundPage from './pages/notFoundPage/nfp.js';
import LogIn from './pages/login/login.js';
import ProjectSearch from './pages/search/search.js';

import InProgressProjects from './pages/projects/inProgressProjects.js';

import ViewProjectsYear from './pages/projects/viewProjectsYear.js';
import NewProject from './pages/projects/newProject.js';
import ViewProject from './pages/projects/viewProject.js';

import SettingsProject from './pages/projects/settingsProject.js';
import SettingsProjectBudget from './pages/projects/netBudgetRate.js';
import SettingsProjectPositionList from './pages/projects/positionList.js';
import SettingsProjectMEP from './pages/projects/mepPurchaseList.js';
import SettingsProjectSCP from './pages/projects/scpPurchaseList.js';

import NewProposal from './pages/proposals/newProposal.js';
import ProposalList from './pages/proposals/proposalList.js';
import ViewProposal from './pages/proposals/viewProposal.js';
import ProposalListFiltered from './pages/proposals/proposalListFiltered.js';
import SearchProposal from './pages/proposals/searchProposal.js';
import ProposalSettings from './pages/proposals/proposalSettings.js';

import NewClient from './pages/clients/clientCreate.js';
import ClientList from './pages/clients/clientList.js';
import ClientView from './pages/clients/clientView.js';
import ClientSettings from './pages/clients/clientSettings.js';

import NewContact from './pages/clients/contactCreate.js';
import ContactList from './pages/clients/contactList.js';
import ContactView from './pages/clients/contactView.js';

import MEPVendorList from './pages/vendors/mepVendorList.js';
import MEPVendorView from './pages/vendors/mepVendorView.js';
import MEPVendorCreate from './pages/vendors/mepVendorCreate.js';

import SUBVendorList from './pages/vendors/subVendorList.js';
import SUBVendorView from './pages/vendors/subVendorView.js';
import SUBVendorCreate from './pages/vendors/subVendorCreate.js';

import VendorSettings from './pages/vendors/vendorSettings.js';

// import TeamMembers from './TeamMembers';
// import BudgetAllocation from './BudgetAllocation';
// import SubconsultantServiceOrders from './SubconsultantServiceOrders';
// import MaterialsOrEquipmentPurchaseOrders from './MaterialsOrEquipmentPurchaseOrders';
// import ChangeOrders from './ChangeOrders';
// import ProjectServicesAndMilestones from './ProjectServicesAndMilestones';
// import DeleteProject from './DeleteProject';


import EmployeeDirectory from './pages/hr/employees/employeeDirectory.js';
import EmployeeList from './pages/hr/employees/staffList.js';
import EmployeeView from './pages/hr/employees/staffView.js';


import TimesheetSubmit from './pages/hr/timesheetSubmit.js';

function App() {
  const isAuthenticated = Boolean(localStorage.getItem('token'));
  const lastActivity = useRef(localStorage.getItem('lastActivity') || Date.now());
  // localStorage.removeItem('token'); use for logout later


  useEffect(() => {
    const setActivity = () => {
      lastActivity.current = Date.now();
    };
    window.addEventListener('mousemove', setActivity);
    window.addEventListener('click', setActivity);
    window.addEventListener('keydown', setActivity);
    window.addEventListener('scroll', setActivity);
    window.addEventListener('beforeunload', setActivity);
    return () => {
      window.removeEventListener('mousemove', setActivity);
      window.removeEventListener('click', setActivity);
      window.removeEventListener('keydown', setActivity);
      window.removeEventListener('scroll', setActivity);
      window.removeEventListener('beforeunload', setActivity);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('lastActivity', lastActivity.current);
    const timeout = setTimeout(() => {
      if (Date.now() - lastActivity.current > 30 * 60 * 1000) {
        // 30 minutes of inactivity -> remove token
        localStorage.removeItem('token');
      }
    },  30 * 60  * 1000);
    return () => clearTimeout(timeout);
  }, []);


  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LogIn />} />
        <Route path="about" element={isAuthenticated ? <AboutPage /> : <Navigate to="/login" />} />
        <Route path="/search" element={isAuthenticated ? <ProjectSearch /> : <Navigate to="/login" />} />
        <Route path="/search/:keyword" element={isAuthenticated ? <ProjectSearch /> : <Navigate to="/login" />} />
        <Route path="/" element={isAuthenticated ? <Homepage /> : <Navigate to="/login" />} />

        <Route path="/yourProjects" element={isAuthenticated ? <InProgressProjects /> : <Navigate to="/login" />} />
        <Route path="/view-projects/:year" element={isAuthenticated ? <ViewProjectsYear /> : <Navigate to="/login" />} />

        <Route path="/projects/new" element={isAuthenticated ? <NewProject /> : <Navigate to="/login" />} />
        <Route path="/viewProject/:projectNumber" element={isAuthenticated ? <ViewProject /> : <Navigate to="/login"/>} />
        <Route path="/projects/settings" element={isAuthenticated ? <SettingsProject /> : <Navigate to="/login" />} />
        <Route path="/projects/settings/budget-allocation" element={isAuthenticated ? <SettingsProjectBudget /> : <Navigate to="/login" />} />
        <Route path="/projects/settings/positionList" element={isAuthenticated ? <SettingsProjectPositionList /> : <Navigate to="/login" />} />
        <Route path="/projects/settings/mep" element={isAuthenticated ? <SettingsProjectMEP /> : <Navigate to="/login" />} />
        <Route path="/projects/settings/scp" element={isAuthenticated ? <SettingsProjectSCP /> : <Navigate to="/login" />} />

        <Route path="/proposals/new" element={isAuthenticated ? <NewProposal /> : <Navigate to="/login" />} />
        <Route path="/proposals/list" element={isAuthenticated ? <ProposalList /> : <Navigate to="/login" />} />
        <Route path="/proposals/view/:proposalNumber" element={isAuthenticated ? <ViewProposal /> : <Navigate to="/login" />} />
        <Route path="/proposals/search" element={isAuthenticated ? <SearchProposal /> : <Navigate to="/login" />} />
        <Route path="/proposals/search/:keyword" element={isAuthenticated ? <SearchProposal /> : <Navigate to="/login" />} />
        <Route path="/proposals/:year/:type" element={isAuthenticated ? <ProposalListFiltered /> : <Navigate to="/login" />} />
        <Route path="/proposals/settings" element={isAuthenticated ? <ProposalSettings /> : <Navigate to="/login" />} />

        <Route path="/clients/new" element={isAuthenticated ? <NewClient /> : <Navigate to="/login" />} />
        <Route path="/clients/clientList" element={isAuthenticated ? <ClientList /> : <Navigate to="/login" />} />
        <Route path="/clients/:clientNumber" element={isAuthenticated ? <ClientView /> : <Navigate to="/login" />} />
        <Route path="/contacts/new" element={isAuthenticated ? <NewContact /> : <Navigate to="/login" />} />
        <Route path="/contacts/contactList" element={isAuthenticated ? <ContactList /> : <Navigate to="/login" />} />
        <Route path="/contacts/:contactNumber" element={isAuthenticated ? <ContactView /> : <Navigate to="/login" />} />
        <Route path="/clientSettings" element={isAuthenticated ? <ClientSettings /> : <Navigate to="/login" />} />


        <Route path="/vendors/MEP" element={isAuthenticated ? <MEPVendorList /> : <Navigate to="/login" />} />
        <Route path="/vendors/MEP/:vendorNumber" element={isAuthenticated ? <MEPVendorView /> : <Navigate to="/login" />} />
        <Route path="/vendors/createMEP" element={isAuthenticated ? <MEPVendorCreate /> : <Navigate to="/login" />} />
        <Route path="/vendors/SUB" element={isAuthenticated ? <SUBVendorList /> : <Navigate to="/login" />} />
        <Route path="/vendors/SUB/:subNumber" element={isAuthenticated ? <SUBVendorView /> : <Navigate to="/login" />} />
        <Route path="/vendors/createSUB" element={isAuthenticated ? <SUBVendorCreate /> : <Navigate to="/login" />} />
        <Route path="/vendors/settings" element={isAuthenticated ? <VendorSettings /> : <Navigate to="/login" />} />

        {/* <Route path="/projects/team" element={isAuthenticated ? <TeamMembers /> : <Navigate to="/login" />} /> */}
        {/* <Route path="/projects/budget-allocation" element={isAuthenticated ? <BudgetAllocation /> : <Navigate to="/login" />} /> */}
        {/* <Route path="/projects/scp" element={isAuthenticated ? <SubconsultantServiceOrders /> : <Navigate to="/login" />} /> */}
        {/* <Route path="/projects/mep" element={isAuthenticated ? <MaterialsOrEquipmentPurchaseOrders /> : <Navigate to="/login" />} /> */}
        {/* <Route path="/projects/co" element={isAuthenticated ? <ChangeOrders /> : <Navigate to="/login" />} /> */}
        {/* <Route path="/projects/services" element={isAuthenticated ? <ProjectServicesAndMilestones /> : <Navigate to="/login" />} /> */}
        {/* <Route path="/projects/delete" element={isAuthenticated ? <DeleteProject /> : <Navigate to="/login" />} /> */}


        <Route path="/hr/employees/directory" element={isAuthenticated ? <EmployeeDirectory /> : <Navigate to="/login" />} />
        <Route path="/hr/employees/records" element={isAuthenticated ? <EmployeeList /> : <Navigate to="/login" />} />
        {/* <Route path="/hr/employees/new" element={isAuthenticated ? <EmployeeDirectory /> : <Navigate to="/login" />} /> */}
        <Route path="/hr/employees/view/:staffNumber" element={isAuthenticated ? <EmployeeView /> : <Navigate to="/login" />} />

        <Route path="/hr/timesheet" element={isAuthenticated ? <TimesheetSubmit /> : <Navigate to="/login" />} />

        <Route path="/*" element={<NotFoundPage />} /> {/* Catch-all route */}
      </Routes>
    </Router>
  );
}

export default App;
