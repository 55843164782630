import React from 'react';
import './header.css'; 
import image from '../ziyutec logo long.png'; 
import Sidebar from './sidebar.js';

class Header extends React.Component {
    
  constructor(props) {
    super(props);
    this.state = { showDropdown: false };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = { isSidebarOpen: false }; 
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleDropdown() {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
  }

  toggleSidebar() {
    this.setState(prevState => ({ isSidebarOpen: !prevState.isSidebarOpen }));
  }

  
  render() {
    const staffName = localStorage.getItem('staffName');
    return (
        <header className="Header">
            <div className="logo">
                <a href="/">
                    <img src={image} alt="Company Logo" /> {}
                </a>
            </div>
            <nav className="navigation">
                <div className="dropdown">
                    <a className="homebtn" href="/">HOME</a>
                </div>
                <div className="dropdown">
                    <span className="dropbtn">PROJECTS</span>
                    <div className="dropdown-content">
                        <a href="/projects/new">New Project</a>
                        <a href="/projects/settings">Project Settings</a>
                        <a href="/search">Project Search</a>
                        <a href="/projects/design-tools">Design Tools</a>
                        <a href="/projects/todo">To-Do List</a>
                    </div>
                </div>
                <div className="dropdown">
                    <span className="dropbtn">PROPOSALS</span>
                    <div className="dropdown-content">
                        <a href="/proposals/new">New Proposal</a>
                        <a href="/proposals/list">Proposal List</a>
                        <a href="/proposals/search">Search Proposals</a>
                        <a href="/proposals/settings">Proposal Settings</a>
                    </div>
                </div>
                <div className="dropdown">
                    <span className="dropbtn">CLIENTS</span>
                    <div className="dropdown-content">
                        <a href="/clients/clientList">Clients</a>
                        <a href="/contacts/contactList">Contact Persons</a>
                        <a href="/clientSettings">Client Settings</a>
                    </div>
                </div>
                <div className="dropdown">
                    <span className="dropbtn">VENDORS</span>
                    <div className="dropdown-content">
                        <a href="/vendors/MEP">MEP Vendors</a>
                        <a href="/vendors/sub">Sub-consultants</a>
                        <a href="/vendors/settings">Vendor Settings</a>
                    </div>
                </div>
                <div className="dropdown">
                    <span className="dropbtn">HR</span>
                    <div className="dropdown-content">
                        <a href="/hr/employees/directory">Employee Directory</a>
                        <a href="/hr/timesheet">Timesheet</a>
                        <a href="/hr/leave">Leave Application</a>
                        <a href="/hr/overtime">Overtime Application</a>
                        <a href="/hr/utilization">Utilization Report</a>
                        <a href="/hr/attendance">Attendance Report</a>
                        <a href="/hr/settings">HR Settings</a>
                    </div>
                </div>
            </nav>
            <nav className='profile'> 
              <span className="dropbtnU" onClick={this.toggleSidebar}>{staffName}</span>
            </nav>
            <Sidebar isOpen={this.state.isSidebarOpen} onClose={this.toggleSidebar} staffName={this.props.staffName} />
        </header>
    );
}
}

export default Header;