import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './proposalList.css'; 
import Header from '../../misc/header';
import ProposalSearchForm from '../../misc/searchformProposal';

const years = [2024, 2023, 2022, 2021, 2020, 2019];

function ProposalList() {
    const [proposalData, setProposalData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProposalData = async () => {
            const data = [];
            for (const year of years) {
                try {
                    const response = await axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/proposals/${year}`);
                    data.push({ year, ...response.data });
                } catch (error) {
                    console.error(`Error fetching data for year ${year}:`, error);
                }
            }
            setProposalData(data);
            setLoading(false);
        };
        fetchProposalData();
    }, []);

    return (
        <>
            <Header />
            {loading ? (
                <div className='Loading'>Loading...</div>
            ) : (
                <div className="proposal-list-container">
                    <div className="proposal-list-header">
                        <div className='proposal-list-title'>PROPOSAL LIST</div>
                        <a href='/proposals/new' className='createVendorButton'>New Proposal</a>
                    </div>
                    <table className="proposal-table">
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Total Proposals</th>
                                <th>Currently Preparing</th>
                                <th>Submitted Proposals</th>
                                <th>Other</th>
                                <th>Under Evaluation</th>
                                <th>Failed Proposals</th>
                                <th>Awarded Proposals</th>
                                <th>Rate of Award (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {proposalData.map((row) => (
                                <tr key={row.year}>
                                    <td>{row.year}</td>
                                    <td>{row.totalProposals === 0 ? row.totalProposals : <a href={`/proposals/${row.year}/total`}>{row.totalProposals}</a>}</td>
                                    <td>{row.preparingProposals === 0 ? row.preparingProposals : <a href={`/proposals/${row.year}/Preparing`}>{row.preparingProposals}</a>}</td>
                                    <td>{row.submittedProposals === 0 ? row.submittedProposals : <a href={`/proposals/${row.year}/submitted`}>{row.submittedProposals}</a>}</td>
                                    <td>{row.otherProposals === 0 ? row.otherProposals : <a href={`/proposals/${row.year}/other`}>{row.otherProposals}</a>}</td>
                                    <td>{row.underEvaluationProposals === 0 ? row.underEvaluationProposals : <a href={`/proposals/${row.year}/under-evaluation`}>{row.underEvaluationProposals}</a>}</td>
                                    <td>{row.failedProposals === 0 ? row.failedProposals : <a href={`/proposals/${row.year}/Failed`}>{row.failedProposals}</a>}</td>
                                    <td>{row.awardedProposals === 0 ? row.awardedProposals : <a href={`/proposals/${row.year}/Awarded`}>{row.awardedProposals}</a>}</td>
                                    <td>{((row.awardedProposals / row.submittedProposals) * 100).toFixed(2)}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <ProposalSearchForm />
                </div>
            )}
        </>
    );
}

export default ProposalList;