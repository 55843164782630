import React from 'react';

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { keyword: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ keyword: event.target.value });
  }
  
  handleSubmit(event) {
    event.preventDefault();
    window.location.href = `/proposals/search/${encodeURIComponent(this.state.keyword)}`; 
  }

  render() {
    return (
      <form className="searchBar" onSubmit={this.handleSubmit}>
        <div>
          <strong>Proposal Keyword Search:</strong>
          <input className="searchBarInput" type="text" name="KeyWordSearch" value={this.state.keyword} onChange={this.handleChange} />
          <input className="searchBarSubmit" type="submit" value="Search" />
        </div>
      </form>
    );
  }
}

export default SearchForm;