import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './timesheetSubmit.css';
import Header from '../../misc/header';

const Timesheet = () => {
    const [week, setWeek] = useState('');
    const [projects, setProjects] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    const [selectedWeek, setSelectedWeek] = useState('');
    const [weeks, setWeeks] = useState([]);

    useEffect(() => {
        const staffName = localStorage.getItem('staffName');
        console.log('Staff Name:', staffName); // Log staffName to console

        axios.post('http://localhost:3000/api/fetchTimesheetData', { staffName })
            .then(response => {
                console.log('Fetch Timesheet Data Response:', response.data); // Log response data
                setProjects(response.data);
                addNewRow();
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });

        generateWeeks();
    }, []);

    const addNewRow = () => {
        setRows([...rows, { project: '', discipline: '', position: '', hours: Array(7).fill('') }]);
    };

    const handleInputChange = (index, dayIndex, value) => {
        const updatedRows = [...rows];
        updatedRows[index].hours[dayIndex] = value;
        setRows(updatedRows);
        calculateTotalHours();
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        if (field === 'project') {
            updatedRows[index].discipline = '';
            updatedRows[index].position = '';
        }
        setRows(updatedRows);
    };

    const calculateTotalHours = () => {
        let total = 0;
        rows.forEach(row => {
            total += row.hours.reduce((sum, hour) => sum + parseFloat(hour || 0), 0);
        });
        setTotalHours(total);
    };

    const handleSubmit = () => {
        // Submit the time sheet data
        axios.post('http://localhost:3000/api/submitTimesheet', { week, rows })
            .then(response => {
                console.log('Time sheet submitted:', response.data);
            })
            .catch(error => {
                console.error('Error submitting time sheet:', error);
            });
    };

    const generateWeeks = () => {
        const weeksArray = [];
        const currentDate = new Date();
        for (let i = -10; i <= 10; i++) {
            const startOfWeek = new Date(currentDate);
            startOfWeek.setDate(currentDate.getDate() + i * 7 - currentDate.getDay());
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            const formattedWeek = `${startOfWeek.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}-${endOfWeek.toLocaleDateString('en-US', { day: 'numeric' })}`;
            weeksArray.push({
                label: formattedWeek,
                value: {
                    year: startOfWeek.getFullYear(),
                    weekNumber: getISOWeekNumber(startOfWeek)
                }
            });
        }
        setWeeks(weeksArray);
        // Set the current week as default
        const currentWeekIndex = weeksArray.findIndex(week => week.value.weekNumber === getISOWeekNumber(currentDate) && week.value.year === currentDate.getFullYear());
        setSelectedWeek(weeksArray[currentWeekIndex].value);
        console.log('Selected Week:', weeksArray[currentWeekIndex]); // Log selected week
    };

    function getISOWeekNumber(date) {
        const tempDate = new Date(date.getTime());
        tempDate.setHours(0, 0, 0, 0);
        tempDate.setDate(tempDate.getDate() + 3 - (tempDate.getDay() + 6) % 7);
        const week1 = new Date(tempDate.getFullYear(), 0, 4);
        return 1 + Math.round(((tempDate.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    return (
        <>
        <Header />
            <div className="timesheet-container">
                <h1>Weekly Time Sheet</h1>
                <div className="week-selector">
                    <label>Select Week: </label>
                    <select
                        value={JSON.stringify(selectedWeek)}
                        onChange={(e) => setSelectedWeek(JSON.parse(e.target.value))} 
                    >
                        {weeks.map((week, index) => (
                            <option key={index} value={JSON.stringify(week.value)}>{week.label}</option> 
                        ))}
                    </select>
                </div>
                <div className="table-container">
                    <table className="timesheet-table">
                        <thead>
                            <tr>
                                <th>Project</th>
                                <th>Discipline</th>
                                <th>Position</th>
                                <th className="day-header">Sun</th>
                                <th className="day-header">Mon</th>
                                <th className="day-header">Tue</th>
                                <th className="day-header">Wed</th>
                                <th className="day-header">Thu</th>
                                <th className="day-header">Fri</th>
                                <th className="day-header">Sat</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <select value={row.project} onChange={(e) => handleRowChange(index, 'project', e.target.value)}>
                                        <option value="">Select Project</option>
                                        {projects.map((project, i) => (
                                            <option key={i} value={project.Project_Number}>{project.Project_Name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select value={row.discipline} onChange={(e) => handleRowChange(index, 'discipline', e.target.value)} disabled={!row.project}>
                                        <option value="">Select Discipline</option>
                                        {projects.filter(p => p.Project_Number === row.project).map((project, i) => (
                                            <option key={i} value={project.Discipline}>{project.Discipline}</option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select value={row.position} onChange={(e) => handleRowChange(index, 'position', e.target.value)} disabled={!row.project}>
                                        <option value="">Select Position</option>
                                        {projects.filter(p => p.Project_Number === row.project).map((project, i) => (
                                            <option key={i} value={project.Position}>{project.Position}</option>
                                        ))}
                                    </select>
                                </td>
                                {row.hours.map((hour, dayIndex) => (
                                    <td key={dayIndex} className="day-cell">
                                        <input
                                            type="number"
                                            inputMode="numeric"
                                            value={hour}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*\.?\d*$/.test(value)) {
                                                    handleInputChange(index, dayIndex, value);
                                                }
                                            }}
                                            onBlur={(e) => {
                                                const value = e.target.value;
                                                if (!isNaN(value) && value.trim() !== '') {
                                                    handleInputChange(index, dayIndex, value);
                                                } else {
                                                    handleInputChange(index, dayIndex, '');
                                                }
                                            }}
                                        />
                                    </td>
                                ))}
                                <td>{row.hours.reduce((sum, hour) => sum + parseFloat(hour || 0), 0)}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="timesheet-footer">
                    <button onClick={addNewRow} className="add-row-btn">Add Row</button>
                    <div className="total-hours">
                        <span>Total Hours: </span><span>{totalHours}</span>
                    </div>
                    <button onClick={handleSubmit} className="submit-btn">Submit</button>
                </div>
            </div>
        </>
    );
};

export default Timesheet;