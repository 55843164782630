import React, { useState } from 'react';
import axios from 'axios';
import Header from '../../misc/header';
import { useNavigate } from 'react-router-dom';
import './clientList.css';

function ClientCreate() {
    const navigate = useNavigate();
    const [client, setClient] = useState({
        customer_number: '',
        customer_name: '',
        C_Type: '',
        C_City: '',
        C_Postal_Code: '',
        C_Address: '',
        C_Province: '',
        C_Tel_1: '',
        C_Tel_2: '',
        C_Tel_D: '',
        C_email: '',
        C_Web_address: '',
        C_Note: '',
        C_BAD_Flag: '',
        C_Project_qty: '',
        C_Project_amount: '',
        C_Total_Contract_Amount: ''
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClient({ ...client, [name]: value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/createClient', client);
            navigate('/clients/' + response.data.customer_number);
        } catch (error) {
            console.error('Error creating client:', error);
        } finally {
            setLoading(false);
        }
    };

    
    return (
        <>
            <Header />
            <div className="clientViewContainer">
                {loading ? (
                    <div className='Loading'>Loading...</div>
                ) : (
                    <div className="clientDetail">
                        <div className="clientDetailTop">
                            <h1 className="clientNumber">Create Client</h1>
                            <button className="updateButton" onClick={handleSubmit}>Create Client</button>
                        </div>
                        <form className="clientForm">
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Client Number:</label>
                                    <input type="text" name="customer_number" value={client.customer_number} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Client Name:</label>
                                    <input type="text" name="customer_name" value={client.customer_name} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Client Type:</label>
                                    <input type="text" name="C_Type" value={client.C_Type} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Address:</label>
                                    <input type="text" name="C_Address" value={client.C_Address} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Postal Code:</label>
                                    <input type="text" name="C_Postal_Code" value={client.C_Postal_Code} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>City:</label>
                                    <input type="text" name="C_City" value={client.C_City} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Province:</label>
                                    <input type="text" name="C_Province" value={client.C_Province} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Web:</label>
                                    <input type="text" name="C_Web_address" value={client.C_Web_address} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Tel 1:</label>
                                    <input type="text" name="C_Tel_1" value={client.C_Tel_1} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Tel 2:</label>
                                    <input type="text" name="C_Tel_2" value={client.C_Tel_2} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Direct Phone:</label>
                                    <input type="text" name="C_Tel_D" value={client.C_Tel_D} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Email:</label>
                                    <input type="text" name="C_email" value={client.C_email} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                            <div className="formGroup">
                                <label>Project Quantity:</label>
                                <input type="text" name="C_Project_qty" value={client.C_Project_qty} onChange={handleChange} />
                            </div>
                            <div className="formGroup">
                                <label>Project Amount:</label>
                                <input type="text" name="C_Project_amount" value={client.C_Project_amount} onChange={handleChange} />
                            </div>
                            <div className="formGroup">
                                <label>Total Contract Amount:</label>
                                <input type="text" name="C_Total_Contract_Amount" value={client.C_Total_Contract_Amount} onChange={handleChange} />
                            </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Notes:</label>
                                    <textarea name="C_Note" value={client.C_Note} onChange={handleChange}></textarea>
                                </div>
                            </div>
                            <div className="formRow formRowBottom">
                                <div className="formGroup">
                                    <label>Bad Flag:</label>
                                    <select name="C_BAD_Flag" value={client.C_BAD_Flag} onChange={handleChange}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
}
export default ClientCreate;