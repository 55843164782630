import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from '../../misc/header';
import './contactView.css';

function ContactView() {
    const { contactNumber } = useParams(); 
    const [contact, setContact] = useState({
        C_C_Number: '',
        C_C_Name: '',
        C_C_Phone_1: '',
        C_C_Phone_2: '',
        C_C_Email_W: '',
        C_C_Email_P: '',
        C_C_Note: ''
    });
    
    const [loading, setLoading] = useState(true);

    const fetchContact = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/contacts/${contactNumber}`);
            setContact(response.data);
            console.log('Contact:', response.data);
        } catch (error) {
            console.error('Error fetching contact:', error);
        } finally {
            setLoading(false);
        }
    }, [contactNumber]);

    useEffect(() => {
        fetchContact();
    }, [fetchContact]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContact(prevContact => ({
            ...prevContact,
            [name]: value
        }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            console.log('Updating contact:', contact);
            await axios.put(`https://ziyutecerp-9re9z.ondigitalocean.app/api/updateContact/${contactNumber}`, contact);
            window.location.href = '/contacts/' + contact.C_C_Number;
        } catch (error) {
            console.error('Error updating contact:', error);
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        const confirmDelete = window.confirm('Are you sure you want to delete this contact?');
        if (confirmDelete) {
            try {
                await axios.delete(`https://ziyutecerp-9re9z.ondigitalocean.app/api/deleteContact/${contactNumber}`);
                window.location.href = '/contacts/contactList';
            } catch (error) {
                console.error('Error deleting contact:', error);
            }
        }
    };

    return (
        <>
            <Header />
            <div className="contactViewContainer">
                {loading ? (
                    <div className='Loading'>Loading...</div>
                ) : (
                    <div className="contactDetail">
                        <div className="contactDetailTop">
                            <h1 className="contactNumber">Contact Number: {contact.C_C_Number}</h1>
                            <button onClick={handleUpdate} className="updateButton">Update</button>
                        </div>
                        <form className="contactForm">
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Contact Number:</label>
                                    <input type="text" name="C_C_Number" value={contact.C_C_Number} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Name:</label>
                                    <input type="text" name="C_C_Name" value={contact.C_C_Name} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Phone 1:</label>
                                    <input type="text" name="C_C_Phone_1" value={contact.C_C_Phone_1} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Phone 2:</label>
                                    <input type="text" name="C_C_Phone_2" value={contact.C_C_Phone_2} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Email (Work):</label>
                                    <input type="text" name="C_C_Email_W" value={contact.C_C_Email_W} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Email (Personal):</label>
                                    <input type="text" name="C_C_Email_P" value={contact.C_C_Email_P} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Notes:</label>
                                    <textarea name="C_C_Note" value={contact.C_C_Note} onChange={handleChange}></textarea>
                                </div>
                            </div>
                            <div className="formRow formRowBottom">
                                <div className="deleteButtonContainer">
                                    <button onClick={handleDelete} className="deleteButton">Delete</button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
}

export default ContactView;