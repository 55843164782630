import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './netBudgetRate.css';
import Header from '../../misc/header';

function NetBudgetRate() {
    const [projectNumber, setProjectNumber] = useState('');
    const [budgetRates, setBudgetRates] = useState({});
    const [total, setTotal] = useState(0);
    const [isValid, setIsValid] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [projectExists, setProjectExists] = useState(false);


    useEffect(() => {
        if (submitted) {
            axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/netBudgetRate/${projectNumber}`)
                .then(response => {
                    const data = response.data;
                    const sanitizedData = Object.fromEntries(
                        Object.entries(data).map(([key, value]) => [key, value || 0])
                    );
                    setBudgetRates(sanitizedData);
                    setProjectExists(true);
                })
                .catch(error => {
                    console.error('Failed to fetch project data:', error);
                    alert('Project not found. Please check the project number and try again.');
                    setProjectExists(false);
                    setSubmitted(false);
                });
        }
    }, [projectNumber, submitted]);
    
    useEffect(() => {
        const sum = Object.entries(budgetRates)
            .filter(([key]) => key !== 'Project_Number' && key !== 'SN' && key !== 'BA_Number')
            .reduce((acc, [key, val]) => acc + parseFloat(val || 0), 0);
        setTotal(sum);
        setIsValid(sum === 100);
    }, [budgetRates]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setBudgetRates(prevState => ({
            ...prevState,
            [name]: value || 0 
        }));
    };
    
    const handleSave = () => {
        if (!isValid) return;
    
        console.log('Saving net budget rates for project number:', projectNumber);
        console.log('Budget Rates:', budgetRates);
    
        axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/updateNetBudgetRate', { projectNumber, budgetRates })
            .then(response => {
                alert('Net budget rates updated successfully');
                window.location.reload();
            })
            .catch(error => {
                console.error('Failed to update net budget rates:', error);
            });
    };
    
    const handleProjectNumberSubmit = () => {
        console.log('Submitted project number:', projectNumber);
        setSubmitted(true);
    };
    
    

        return (
            <>
                <Header />
                <div className="net-budget-rate-container">
                    <h2>Net Budget Rate Allocation</h2>
                    {!submitted || !projectExists ? (
                        <div className="form-row">
                            <label>Project Number:</label>
                            <input
                                type="text"
                                value={projectNumber}
                                onChange={(e) => setProjectNumber(e.target.value)}
                                className="short-input"
                            />
                            <button onClick={handleProjectNumberSubmit}>Submit</button>
                        </div>
                    ) : (
                        <>
                            <div className="budget-form">
                                <div className="form-row">
                                    <label>Project Number:</label>
                                    <span>{projectNumber}</span>
                                </div>
                                <div className="form-row">
                                    <label>BA Number:</label>
                                    <span>{budgetRates.BA_Number || 0}</span>
                                </div>
                                {Object.keys(budgetRates)
                                    .filter(key => key !== 'SN' && key !== 'BA_Number' && key !== 'Project_Number')
                                    .map((key, index) => (
                                        <div className="form-row" key={index}>
                                            <label>{key.replace('_', ' ')}:</label>
                                            <input
                                                type="text"
                                                name={key}
                                                value={budgetRates[key]}
                                                onChange={handleChange}
                                                className="short-input"
                                            />
                                        </div>
                                    ))}
                            </div>      
        
                            <div className="form-row total-row">
                                <label>Total:</label>
                                <input type="text" value={total} readOnly className="short-input" />
                            </div>
        
                            <div className="form-row">
                                <button disabled={!isValid} onClick={handleSave}>Save</button>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }
    
    export default NetBudgetRate;