import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './proposalSettings.css';
import Header from '../../misc/header';

function ProposalSettings() {
    const [proposalStatus, setProposalStatus] = useState([]);
    const [proposalType, setProposalType] = useState([]);
    const [proposalAttribute, setProposalAttribute] = useState([]);
    const [newProposalStatus, setNewProposalStatus] = useState({ typeName: '', notes: '' });
    const [newProposalType, setNewProposalType] = useState({ typeName: '', notes: '' });
    const [newProposalAttribute, setNewProposalAttribute] = useState({ typeName: '', notes: '' });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const statusResponse = await axios.get('https://ziyutecerp-9re9z.ondigitalocean.app/api/proposal-statuses');
            const typeResponse = await axios.get('https://ziyutecerp-9re9z.ondigitalocean.app/api/proposal-types');
            const attributeResponse = await axios.get('https://ziyutecerp-9re9z.ondigitalocean.app/api/proposal-attributes');
            setProposalStatus(statusResponse.data);
            setProposalType(typeResponse.data);
            setProposalAttribute(attributeResponse.data);
        } catch (error) {
            console.error('Failed to fetch data', error);
        }
    };

    const handleAddStatus = async () => {
        try {
            await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/proposal-statuses', newProposalStatus);
            setNewProposalStatus({ typeName: '', notes: '' });
            fetchData();
        } catch (error) {
            console.error('Failed to add proposal status', error);
        }
    };

    const handleAddType = async () => {
        try {
            await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/proposal-types', newProposalType);
            setNewProposalType({ typeName: '', notes: '' });
            fetchData();
        } catch (error) {
            console.error('Failed to add proposal type', error);
        }
    };

    const handleAddAttribute = async () => {
        try {
            await axios.post('https://ziyutecerp-9re9z.ondigitalocean.app/api/proposal-attributes', newProposalAttribute);
            setNewProposalAttribute({ typeName: '', notes: '' });
            fetchData();
        } catch (error) {
            console.error('Failed to add proposal attribute', error);
        }
    };

    const handleDeleteStatus = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this proposal status?');
        if (confirmDelete) {
            try {
                await axios.delete(`https://ziyutecerp-9re9z.ondigitalocean.app/api/proposal-statuses/${id}`);
                fetchData();
            } catch (error) {
                console.error('Failed to delete proposal status', error);
            }
        }
    };

    const handleDeleteType = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this proposal type?');
        if (confirmDelete) {
            try {
                await axios.delete(`https://ziyutecerp-9re9z.ondigitalocean.app/api/proposal-types/${id}`);
                fetchData();
            } catch (error) {
                console.error('Failed to delete proposal type', error);
            }
        }
    };

    const handleDeleteAttribute = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this proposal attribute?');
        if (confirmDelete) {
            try {
                await axios.delete(`https://ziyutecerp-9re9z.ondigitalocean.app/api/proposal-attributes/${id}`);
                fetchData();
            } catch (error) {
                console.error('Failed to delete proposal attribute', error);
            }
        }
    };

    return (
        <>
            <Header />
            <div className="proposal-settings">
                <h2>Proposal Status Setup</h2>
                <div className="proposal-form">
                    <input
                        type="text"
                        placeholder="Proposal Status"
                        value={newProposalStatus.typeName}
                        onChange={(e) => setNewProposalStatus({ ...newProposalStatus, typeName: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Notes"
                        value={newProposalStatus.notes}
                        onChange={(e) => setNewProposalStatus({ ...newProposalStatus, notes: e.target.value })}
                    />
                    <button onClick={handleAddStatus}>Add Proposal Status</button>
                </div>
                <table className="proposal-table">
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Proposal Status</th>
                            <th>Notes</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {proposalStatus.map((status) => (
                            <tr key={status.SN}>
                                <td>{status.SN}</td>
                                <td>{status.Proposal_Status}</td>
                                <td>{status.Notes}</td>
                                <td><button onClick={() => handleDeleteStatus(status.SN)}>Delete</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h2>Proposal Type Setup</h2>
                <div className="proposal-form">
                    <input
                        type="text"
                        placeholder="Proposal Type"
                        value={newProposalType.typeName}
                        onChange={(e) => setNewProposalType({ ...newProposalType, typeName: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Notes"
                        value={newProposalType.notes}
                        onChange={(e) => setNewProposalType({ ...newProposalType, notes: e.target.value })}
                    />
                    <button onClick={handleAddType}>Add Proposal Type</button>
                </div>
                <table className="proposal-table">
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Proposal Type</th>
                            <th>Notes</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {proposalType.map((type) => (
                            <tr key={type.SN}>
                                <td>{type.SN}</td>
                                <td>{type.Proposal_Type}</td>
                                <td>{type.Notes}</td>
                                <td><button onClick={() => handleDeleteType(type.SN)}>Delete</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h2>Proposal Attribute Setup</h2>
                <div className="proposal-form">
                    <input
                        type="text"
                        placeholder="Attribute"
                        value={newProposalAttribute.typeName}
                        onChange={(e) => setNewProposalAttribute({ ...newProposalAttribute, typeName: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Notes"
                        value={newProposalAttribute.notes}
                        onChange={(e) => setNewProposalAttribute({ ...newProposalAttribute, notes: e.target.value })}
                    />
                    <button onClick={handleAddAttribute}>Add Proposal Attribute</button>
                </div>
                <table className="proposal-table">
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Attribute</th>
                            <th>Notes</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {proposalAttribute.map((attribute) => (
                            <tr key={attribute.SN}>
                                <td>{attribute.SN}</td>
                                <td>{attribute.Attribute}</td>
                                <td>{attribute.Notes}</td>
                                <td><button onClick={() => handleDeleteAttribute(attribute.SN)}>Delete</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ProposalSettings;
