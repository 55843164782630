import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from '../../misc/header';
import './subVendorView.css';

function SubView() {
    const { subNumber } = useParams(); 
    console.log('Sub Number:', subNumber);
    const [sub, setSub] = useState({
        number: '',
        name: '',
        owner: '',
        type: '',
        payment_type: '',
        province: '',
        city: '',
        postal_code: '',
        address: '',
        tel_1: '',
        tel_2: '',
        tel_d: '',
        email: '',
        web: '',
        notes: '',
        bad_flag: ''
    });
    const [loading, setLoading] = useState(true);

    const fetchSub = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/subs/${subNumber}`);
            setSub(response.data);
            console.log('Fetched Sub:', response.data);
        } catch (error) {
            console.error('Error fetching sub:', error);
        } finally {
            setLoading(false);
        }
    }, [subNumber]);

    useEffect(() => {
        fetchSub();
    }, [fetchSub]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSub(prevSub => ({
            ...prevSub,
            [name]: value
        }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://ziyutecerp-9re9z.ondigitalocean.app/api/updateSub/${subNumber}`, sub);
            window.location.href = '/vendors/sub/' + sub.SC_number;
        } catch (error) {
            console.error('Error updating sub:', error);
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        const confirmDelete = window.confirm('Are you sure you want to delete this sub?');
        if (confirmDelete) {
            try {
                await axios.delete(`https://ziyutecerp-9re9z.ondigitalocean.app/api/deleteSub/${subNumber}`);
                window.location.href = '/vendors/sub';
            } catch (error) {
                console.error('Error deleting sub:', error);
            }
        }
    };

    return (
        <>
            <Header />
            <div className="subViewContainer">
                {loading ? (
                    <div className='Loading'>Loading...</div>
                ) : (
                    <div className="subDetail">
                        <div className="subDetailTop">
                            <h1 className="subNumber">Sub-Consultant Number: {sub.SC_number}</h1>
                            <button onClick={handleUpdate} className="updateButton">Update</button>
                        </div>
                        <form className="subForm">
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Sub-Consultant Number:</label>
                                    <input type="text" name="SC_number" value={sub.SC_number} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Sub-Consultant Name:</label>
                                    <input type="text" name="SC_name" value={sub.SC_name} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Owner:</label>
                                    <input type="text" name="SC_Owner" value={sub.SC_Owner} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Type:</label>
                                    <input type="text" name="SC_type" value={sub.SC_type} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Address:</label>
                                    <input type="text" name="SC_Address" value={sub.SC_Address} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Postal Code:</label>
                                    <input type="text" name="SC_Postal_Code" value={sub.SC_Postal_Code} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>City:</label>
                                    <input type="text" name="SC_City" value={sub.SC_City} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Province:</label>
                                    <input type="text" name="SC_Province" value={sub.SC_Province} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Payment Type:</label>
                                    <input type="text" name="SC_Payment_Type" value={sub.SC_Payment_Type} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Tel 1:</label>
                                    <input type="text" name="SC_Tel_1" value={sub.SC_Tel_1} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Tel 2:</label>
                                    <input type="text" name="SC_Tel_2" value={sub.SC_Tel_2} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Direct Phone:</label>
                                    <input type="text" name="SC_Tel_D" value={sub.SC_Tel_D} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Email:</label>
                                    <input type="text" name="SC_Email" value={sub.SC_Email} onChange={handleChange} />
                                </div>
                                <div className="formGroup">
                                    <label>Web:</label>
                                    <input type="text" name="SC_Web" value={sub.SC_Web} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="formRow">
                                <div className="formGroup">
                                    <label>Notes:</label>
                                    <textarea name="SC_Notes" value={sub.SC_Notes} onChange={handleChange}></textarea>
                                </div>
                            </div>
                            <div className="formRow formRowBottom">
                                <div className="formGroup">
                                    <label>Bad Flag:</label>
                                    <select name="SC_Bad_Flag" value={sub.SC_Bad_Flag} onChange={handleChange}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                <div className="deleteButtonContainer">
                                    <button onClick={handleDelete} className="deleteButton">Delete</button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
}

export default SubView;