import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../../misc/header';
import './subVendorList.css';

function SubList() {
    const [subs, setVendors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        fetchVendors();
    }, []);

    const fetchVendors = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://ziyutecerp-9re9z.ondigitalocean.app/api/subs', {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            });
            console.log('Response from Server:', response);
            setVendors(response.data);
            console.log('Fetched Vendors from Server:', response.data);
            if (response.data.length > 0) {
                console.log('First Vendor from Server:', response.data[0]);
            }
        } catch (error) {
            console.error('Error fetching subs from Server:', error);
        } finally {
            setLoading(false);
        }
    };

    const filteredVendors = keyword
        ? subs.filter(vendor =>
            (vendor.SC_name && vendor.SC_name.toLowerCase().includes(keyword.toLowerCase())) ||
            (vendor.SC_type && vendor.SC_type.toLowerCase().includes(keyword.toLowerCase()))
        )
        : subs;

    console.log('Filtered Vendors:', filteredVendors);

    return (
        <>
            <Header />
            <div className="SCsearchBarContainer">
                <div className="SCsearchBar">
                    <input
                        type="text"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder="Sub-Consultant Keyword Search"
                    />
                </div>
                <div className="SCTitleContainer">
                    <div className='SCTitle'>SUB-CONSULTANT LIST</div>
                </div>
                <a href="/vendors/createSUB/" className="createVendorButton">
                    Create New Sub-Consultant
                </a>
            </div>

            {loading ? (
                <div className='Loading'>Loading...</div>
            ) : (
                <div>
                    <table className="subTable">
                        <thead>
                            <tr>
                                <th>Sub-Consultant Number</th>
                                <th>Sub-Consultant Name</th>
                                <th>Sub-Consultant Type</th>
                                <th>Payment Type</th>
                                <th>Address</th>
                                <th>City</th>
                                <th>Office Tel 1</th>
                                <th>Direct Phone</th>
                                <th>Email</th>
                                <th>BAD Sub-Consultant Flag</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredVendors.length > 0 ? (
                                filteredVendors.map(vendor => (
                                    <tr key={vendor.SN}>
                                        <td>
                                            <Link to={`/vendors/SUB/${vendor.SC_number}`} className="vendorLink">
                                                {vendor.SC_number}
                                            </Link>
                                        </td>
                                        <td>{vendor.SC_name}</td>
                                        <td>{vendor.SC_type}</td>
                                        <td>{vendor.SC_payment_type}</td>
                                        <td>{vendor.SC_Address}</td>
                                        <td>{vendor.SC_City}</td>
                                        <td>{vendor.SC_Tel_1}</td>
                                        <td>{vendor.SC_Tel_D}</td>
                                        <td>{vendor.SC_Email}</td>
                                        <td>{vendor.SC_Bad_flag}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10" className="noResults">No results found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default SubList;