import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../../misc/header';
import './viewProposal.css';

function ProposalDetails() {
    const { proposalNumber } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [proposal, setProposal] = useState({
        proposalNumber: '',
        externalOpportunityNumber: '',
        proposalType: '',
        city: '',
        proposalName: '',
        bidClosingDate: '',
        clientName: '',
        createdDate: '',
        submittedDate: '',
        bidPrice: '',
        awardedPrice: '',
        attribute: '',
        disciplineProjectType: '',
        submittedBy1: '',
        submittedBy2: '',
        notes: '',
        status: ''
    });
    const [statusOptions, setStatusOptions] = useState([]);
    const [proposalTypes, setProposalTypes] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [clients, setClients] = useState([]);
    const [staffList, setStaffList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://ziyutecerp-9re9z.ondigitalocean.app/api/getProposal/${proposalNumber}`);
                const proposalData = response.data;
                setProposal({
                    proposalNumber: proposalData.Proposal_Number || '',
                    externalOpportunityNumber: proposalData.External_Opportunity_Number || '',
                    proposalType: proposalData.Proposal_Type || '',
                    city: proposalData.City || '',
                    proposalName: proposalData.Proposal_Name || '',
                    bidClosingDate: proposalData.bid_closing_date || '',
                    clientName: proposalData.Client_Name || '',
                    createdDate: proposalData.Created_date || '',
                    submittedDate: proposalData.Submitted_date || '',
                    bidPrice: proposalData.Bid_Price || '',
                    awardedPrice: proposalData.Awarded_Price || '',
                    attribute: proposalData._Attribute || '',
                    disciplineProjectType: proposalData.Discipline_Project_Type || '',
                    submittedBy1: proposalData.Submitted_by_1 || '',
                    submittedBy2: proposalData.Submitted_by_2 || '',
                    notes: proposalData.Notes || '',
                    status: proposalData.Award_or_Fail || ''
                });

                setStatusOptions(proposalData.statusOptions || []);
                setProposalTypes(proposalData.proposalTypes || []);
                setAttributes(proposalData.attributes || []);
                setClients(proposalData.clients || []);
                setStaffList(proposalData.staffList || []);
            } catch (error) {
                console.error('Failed to fetch proposal data:', error);
            }
            setIsLoading(false);
        };

        fetchData();
    }, [proposalNumber]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProposal(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSave = async () => {
        try {
            const response = await axios.put(`https://ziyutecerp-9re9z.ondigitalocean.app/api/updateProposal/${proposalNumber}`, proposal);
            if (response.status === 200) {
                console.log('Proposal updated successfully');
                window.location.reload();
            }
        } catch (error) {
            console.error('Failed to update proposal:', error);
        }
    };

    return (
        <div className={`proposal-detail ${isLoading ? 'loading' : ''}`}>
            <Header />
            {isLoading ? (
                <h1 className="Loading">Loading...</h1>
            ) : (
                <div className="proposal-container">
                    <h2>Proposal Detail</h2>
                    <form>
                        <div className="form-row">
                            <label>Proposal Number:</label>
                            <input type="text" name="proposalNumber" value={proposal.proposalNumber} onChange={handleChange} readOnly />
                        </div>
                        <div className="form-row">
                            <label>External Opportunity Number:</label>
                            <input type="text" name="externalOpportunityNumber" value={proposal.externalOpportunityNumber} onChange={handleChange} />
                        </div>
                        <div className="form-row">
                            <label>Proposal Type:</label>
                            <select name="proposalType" value={proposal.proposalType} onChange={handleChange}>
                                {proposalTypes.map((type, index) => (
                                    <option key={index} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-row">
                            <label>City:</label>
                            <input type="text" name="city" value={proposal.city} onChange={handleChange} />
                        </div>
                        <div className="form-row">
                            <label>Proposal Name:</label>
                            <input type="text" name="proposalName" value={proposal.proposalName} onChange={handleChange} />
                        </div>
                        <div className="form-row">
                            <label>Bid Closing Date:</label>
                            <input type="date" name="bidClosingDate" value={proposal.bidClosingDate.split('T')[0]} onChange={handleChange} />
                        </div>
                        <div className="form-row">
                            <label>RFP Owner:</label>
                            <select name="clientName" value={proposal.clientName} onChange={handleChange}>
                                {clients.map((client, index) => (
                                    <option key={index} value={client}>{client}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-row">
                            <label>Created Date:</label>
                            <input type="date" name="createdDate" value={proposal.createdDate.split('T')[0]} onChange={handleChange} />
                        </div>
                        <div className="form-row">
                            <label>Submitted Date:</label>
                            <input type="date" name="submittedDate" value={proposal.submittedDate.split('T')[0]} onChange={handleChange} />
                        </div>
                        <div className="form-row">
                            <label>Bid Price:</label>
                            <input type="text" name="bidPrice" value={proposal.bidPrice} onChange={handleChange} />
                        </div>
                        <div className="form-row">
                            <label>Awarded Price:</label>
                            <input type="text" name="awardedPrice" value={proposal.awardedPrice} onChange={handleChange} />
                        </div>
                        <div className="form-row">
                            <label>Attribute:</label>
                            <select name="attribute" value={proposal.attribute} onChange={handleChange}>
                                {attributes.map((attribute, index) => (
                                    <option key={index} value={attribute}>{attribute}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-row">
                            <label>Discipline and Service:</label>
                            <input type="text" name="disciplineProjectType" value={proposal.disciplineProjectType} onChange={handleChange} />
                        </div>
                        <div className="form-row">
                            <label>Submitted by A:</label>
                            <select name="submittedBy1" value={proposal.submittedBy1} onChange={handleChange}>
                                {staffList.map((staff, index) => (
                                    <option key={index} value={staff}>{staff}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-row">
                            <label>Submitted by B:</label>
                            <select name="submittedBy2" value={proposal.submittedBy2} onChange={handleChange}>
                                {staffList.map((staff, index) => (
                                    <option key={index} value={staff}>{staff}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-row">
                            <label>Status:</label>
                            <select name="status" value={proposal.status} onChange={handleChange}>
                                {statusOptions.map((status, index) => (
                                    <option key={index} value={status}>{status}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-row">
                            <label>Notes:</label>
                            <textarea name="notes" value={proposal.notes} onChange={handleChange} rows="5" />
                        </div>
                        <div className="form-row">
                            <button type="button" onClick={handleSave}>Update Proposal</button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}

export default ProposalDetails;
